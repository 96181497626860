import { Table, Row, Modal, Input, Select, Drawer } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortData } from '.';
import { AutoTrackButton, IconButton } from '../../components/button';
import { ICSort, ICAdd, ICNoneData, ICExcel } from '../../icons';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import { CarForm } from './car';
import { TCar, TCarImport } from '../start-work-order/type';
import { AutoTrackPagination } from '../../components/pagination';
import { useNotification } from '../../hooks/useNotification';
import dayjs from 'dayjs';
import { setLoading } from '../../redux/slices/appInfo';
import {
    updateCar,
    createCar,
    deleteCar,
    createCarList,
} from '../../api/setting/cars';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';
import debouce from 'lodash/debounce';
import { removeUndefinedAttribute } from '../../utils';
import { AutoTrackTooltip } from '../../components/tooltip';
import { getImageByLicensePlate } from '../../api/image-service';
import { TImage } from '../../api/image-service/type';
import { useScreenSize } from '../../hooks/useScreenSize';
import { MobileListCarPage } from './mobile-list';
import { ImageListControl } from './image-list';
import { ApiInstance } from '../../api';
import { NameOfCar } from '../components/name-of-car';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ExcelUpload } from './excel-upload';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    padding-right: 16px;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }
`;

const WrapperInput = styled.div`
    @media (max-width: 768px) {
    .ant-input-group-wrapper {
        width: 100% !important;
    }
`;

const WrapperButton = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        .ant-btn {
            width: 100% !important;
        }
    }
`;

type TMessageInfo = {
    code: string;
    message: string;
};

type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type CarPageListProps = {
    carList: TCar[];
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    onReload: () => void;
    filterParams: SearchQueryParams;
    onFilterData: (payload: SearchQueryParams) => void;
};
const KeywordContext = React.createContext<string>('');

export const CarPageList: React.FC<CarPageListProps> = ({
    carList,
    keyword,
    pagination,
    onChangePaging,
    onReload,
    filterParams,
    onFilterData,
}) => {
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [carId, setCarId] = React.useState<string | undefined>('');
    const [showCarDialog, setShowCarDialog] = React.useState(false);
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>(
        undefined
    );
    const [form] = useForm();
    const [imageList, setImageList] = React.useState<TImage[]>([]);
    const [showImageModal, setShowImageModal] = React.useState(false);
    const [showImageDrawerModal, setShowImageDrawerModal] =
        React.useState(false);

    const progressInfosRef = React.useRef<any>(null);
    const [newImageFileList, setNewImageFileList] = React.useState<File[]>([]);

    const [openViewExcelData, setOpenViewExcelData] = React.useState(false);
    const [excelFileSelected, setExcelFileSelected] =
        React.useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [carImportData, setCarImportData] = React.useState<TCarImport[]>([]);

    // handle search keyword
    const [searchColumn, setSearchColumn] = React.useState<string>('Make');
    const [searchWord, setSearchWord] = React.useState<string | undefined>('');
    const selectBeforeOptions = [
        'Make',
        'Model',
        'Year',
        'Color',
        'VIN',
        'LicensePlate',
    ];
    const onSearchData = React.useCallback(
        async (searchWord: string | undefined, searchColumnTemp?: string) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: filterParams?.pageSize || 10,
                    searchWord: searchWord,
                    sortBy: Direction.ASC,
                    searchColum: searchColumnTemp || searchColumn,
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                onFilterData(newPayload);
            } catch (error) {
                // Handle error
            }
        },
        [filterParams, searchColumn]
    );

    const handleSearchData = (value: string | undefined) => {
        onSearchKeyword(value);
        setSearchWord(value);
    };

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchData, 600);
    }, [onSearchData]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        };
    }, [onSearchKeyword]);
    // end handle search keyword

    const onShowDeleteModal = React.useCallback(
        (id: string) => {
            setCarId(id);
            setShowDeleteModal(true);
        },
        [setShowDeleteModal]
    );

    const uploadImagesCar = async (
        carId: string | number,
        messageData: TMessageInfo
    ) => {
        try {
            if (newImageFileList != null && newImageFileList.length > 0) {
                const files = Array.from(newImageFileList);
                const _progressInfos = files.map(file => ({
                    percentage: 0,
                    fileName: file.name,
                }));
                progressInfosRef.current = _progressInfos;
                const result = (await Promise.all(
                    files.map(image =>
                        ApiInstance.uploadCarImages(carId, image, () => {})
                    )
                )) as File[];
                if (result) {
                    showSuccess(messageData.code, messageData.message);
                    setImageList([]);
                    setNewImageFileList([]);
                }
            }
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            showError('Error', message || 'Please fill in all required fields');
        }
    };

    const onViewImages = React.useCallback(
        async (licensePlate: string) => {
            try {
                dispatch(setLoading(true));
                const result = await getImageByLicensePlate(licensePlate);
                if (result.status === 200) {
                    if (result.data?.length > 0) {
                        setImageList(result.data);
                        if (mobileMode) {
                            setShowImageDrawerModal(true);
                        } else {
                            setShowImageModal(true);
                        }
                    } else {
                        setImageList([]);
                        showSuccess('Get images', 'No images found');
                    }
                }
            } catch (error: any) {
                dispatch(setLoading(false));
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Error occurred while getting images';
                showError('Get images failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showSuccess, showError, mobileMode]
    );

    const columns: ColumnsType<TCar> = [
        {
            title: 'Make',
            dataIndex: 'make',
            field: 'make',
            key: 'make',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            field: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            field: 'year',
            key: 'year',
            sorter: true,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            field: 'color',
            key: 'color',
            sorter: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            field: 'vin',
            key: 'vin',
            sorter: true,
        },
        {
            title: 'License Plate',
            dataIndex: 'licensePlate',
            field: 'licensePlate',
            key: 'licensePlate',
            sorter: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            field: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (val: string) => {
                const newDate = dayjs(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return newDate;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'carId',
            key: 'carId',
            width: '40px',
            render: (carId: string, data: TCar) => {
                return (
                    <ActionMenu>
                        <AutoTrackTooltip
                            content="Receipts Images"
                            placement="top"
                            childNode={
                                <IconButton
                                    icon={<ICMaterialSymbol icon="image" />}
                                    btnType="tertiary"
                                    onClick={() => {
                                        setCarSelected(data);
                                        onViewImages(data.licensePlate);
                                    }}
                                />
                            }
                        />
                        <AutoTrackTooltip
                            content="Edit"
                            placement="top"
                            childNode={
                                <IconButton
                                    icon={
                                        <ICMaterialSymbol icon="edit_square" />
                                    }
                                    btnType="tertiary"
                                    onClick={() => {
                                        setCarSelected(data);
                                        setShowCarDialog(true);
                                    }}
                                />
                            }
                        />
                        <AutoTrackTooltip
                            content="Delete"
                            placement="top"
                            childNode={
                                <IconButton
                                    icon={<ICMaterialSymbol icon="delete" />}
                                    onClick={() => onShowDeleteModal(carId)}
                                    btnType="tertiary"
                                />
                            }
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TCar>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    const doSaveCar = React.useCallback(
        async (carId: string | undefined | number, carData: TCar) => {
            try {
                dispatch(setLoading(true));
                if (carId) {
                    await updateCar(carId, carData);
                    await uploadImagesCar(carId, {
                        code: 'Update Car',
                        message: 'Car has been updated',
                    });
                } else {
                    const result = await createCar(carData);
                    if (result.status === 201) {
                        const carIdData = result.data?.carId;
                        if (carIdData) {
                            await uploadImagesCar(carIdData, {
                                code: 'Create Car',
                                message: 'Car has been created',
                            });
                        }
                    }
                }
                onReload();
                setShowCarDialog(false);
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Please check your input data';
                showError('Save Car failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [carList, newImageFileList, dispatch, showSuccess, showError]
    );

    const onSaveCar = React.useCallback(() => {
        form.validateFields()
            .then(values => {
                const carData = {
                    ...carSelected,
                    ...values,
                    year: values.year.format('YYYY'),
                };
                if (carSelected) {
                    doSaveCar(carSelected.carId, carData);
                } else {
                    doSaveCar(undefined, carData);
                }
            })
            .catch(() => {
                showError('Save Car failed', 'Please check your input data');
            });
    }, [form, newImageFileList, carSelected, showError]);

    const onDeleteCar = React.useCallback(async () => {
        if (!carId) {
            return;
        }
        try {
            dispatch(setLoading(true));
            const result = await deleteCar(carId);
            setShowDeleteModal(false);
            if (result.status === 200) {
                showSuccess('Delete Car success', 'Car has been deleted');
                onReload();
            }
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.detail ||
                'Error occurred while deleting Car';
            showError('Delete Car failed', errorMessage);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, carId, showSuccess, showError]);

    const onDownloadTemplate = React.useCallback(() => {
        const link = document.createElement('a');
        link.href = '/file-download/car-import.xlsx';
        link.download = 'car-import.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const handleExcelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0] || null;
        if (selectedFile) {
            setExcelFileSelected(selectedFile);
            setOpenViewExcelData(true);
        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            setExcelFileSelected(null);
            fileInputRef.current.click();
        }
    };

    const parseFormattedNumber = (input: string): number => {
        if (!input) {
            return 0;
        }
        const normalizedInput = input.replace(/,/g, '');
        const parsedNumber = parseFloat(normalizedInput);
        return parsedNumber;
    };

    const formatDateUTC = (date: string): string => {
        if (!date) {
            return '';
        }
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        const utcDate = new Date(formattedDate).toISOString();
        return utcDate;
    };

    const onSaveImportCar = React.useCallback(async () => {
        try {
            if (carImportData.length === 0) {
                showError('Import Car failed', 'No data found');
                return;
            }
            dispatch(setLoading(true));
            const carListTemp: TCar[] = [];
            for (const carData of carImportData) {
                const purchaseDate = carData?.PurchaseDate?.toString();
                const formattedPurchaseDate = formatDateUTC(purchaseDate);
                const registration = carData?.Registration?.toString();
                const formattedRegistration = formatDateUTC(registration);
                const payload: TCar = {
                    make: carData?.Make,
                    model: carData?.Model?.toString(),
                    year: carData?.Year?.toString(),
                    color: carData?.Color,
                    vin: carData?.VIN,
                    licensePlate: carData?.LicensePlate,
                    notes: carData?.Notes,
                    bouncie: carData?.Bouncie,
                    ntta: carData?.NTTA,
                    owner: carData?.Owner,
                    insurance: carData?.Insurance?.toString(),
                    mileage: carData?.Mileage?.toString(),
                    cost: parseFormattedNumber(carData?.Cost?.toString()),
                    registration: formattedRegistration,
                    keys: carData?.Keys?.toString(),
                };
                if (formattedPurchaseDate) {
                    payload.purchaseDate = formattedPurchaseDate;
                }
                carListTemp.push(payload);
            }
            await createCarList(carListTemp);
            onReload();
            showSuccess('Import Car', 'Cars have been imported');
            setOpenViewExcelData(false);
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.detail ||
                'Error occurred while importing cars';
            showError('Import Car failed', errorMessage);
        } finally {
            dispatch(setLoading(false));
        }
    }, [carImportData, dispatch, showSuccess, showError]);

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex w-full px-[12px] py-[20px] shadow-e-03 max-md:justify-center items-center max-md:flex-wrap gap-3 justify-between bg-white rounded-[12px]">
                <div className="flex gap-3 items-center justify-center w-full flex-wrap">
                    <WrapperInput className="flex items-center w-full gap-2">
                        <Input
                            placeholder="Search by ..."
                            value={searchWord}
                            onChange={e => handleSearchData(e.target.value)}
                            style={{
                                width: '420px',
                            }}
                            addonBefore={
                                <>
                                    <Select
                                        className="select-before"
                                        defaultValue="Make"
                                        value={searchColumn}
                                        style={{ width: 122 }}
                                        onChange={(value: string) => {
                                            setSearchColumn(value);
                                            onSearchData(searchWord, value);
                                        }}
                                    >
                                        {selectBeforeOptions.map(
                                            (item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </>
                            }
                            size="large"
                        />
                    </WrapperInput>
                </div>
                <WrapperButton className="flex gap-[10px]">
                    <AutoTrackButton
                        btnType="primary"
                        onClick={() => {
                            setCarSelected(undefined);
                            setShowCarDialog(true);
                        }}
                        style={{
                            minWidth: '120px',
                        }}
                        btnSize="sm"
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#fff" width={18} height={18} />
                            <span>Add a car</span>
                        </div>
                    </AutoTrackButton>
                    <AutoTrackButton
                        btnType="outline"
                        onClick={() => {
                            triggerFileInput();
                        }}
                        style={{
                            minWidth: '120px',
                        }}
                        btnSize="sm"
                    >
                        <div className="flex space-x-2 items-center">
                            <ICExcel stroke="#fff" width={18} height={18} />
                            <span>Import excel</span>
                        </div>
                    </AutoTrackButton>
                    <AutoTrackButton
                        btnType="sub_info"
                        style={{
                            minWidth: '180px',
                        }}
                        btnSize="sm"
                        onClick={onDownloadTemplate}
                    >
                        <div className="flex space-x-2 items-center">
                            <CloudDownloadOutlined
                                style={{ fontSize: '18px' }}
                            />
                            <span>Download template</span>
                        </div>
                    </AutoTrackButton>
                </WrapperButton>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleExcelChange}
                    accept=".xlsx, .xls"
                />
            </div>
            <div
                className={classNames([
                    'flex flex-col w-full h-full rounded-[12px] overflow-hidden shadow-e-03',
                    {
                        'bg-white': !mobileMode,
                    },
                ])}
            >
                <KeywordContext.Provider value={keyword ?? ''}>
                    {mobileMode ? (
                        <MobileListCarPage
                            carList={carList}
                            onViewImages={onViewImages}
                            onEditCar={car => {
                                setCarSelected(car);
                                setShowCarDialog(true);
                            }}
                            onDeleteCar={car => {
                                const carId = car.carId ?? '';
                                onShowDeleteModal(carId.toString());
                            }}
                        />
                    ) : (
                        <Table
                            key="car-list"
                            className={classNames('white-header w-full')}
                            columns={columns}
                            dataSource={carList}
                            rowClassName="cursor-pointer data-row"
                            rowKey="carId"
                            scroll={{ x: 'max-content' }}
                            locale={{
                                emptyText: (
                                    <EmptyTable
                                        keyword={keyword}
                                        onCreateNew={() => {
                                            setCarSelected(undefined);
                                            setShowCarDialog(true);
                                        }}
                                    />
                                ),
                            }}
                            pagination={false}
                        />
                    )}
                </KeywordContext.Provider>
                {pagination?.totalPage && pagination?.totalPage > 1 ? (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className={classNames([
                            'grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]',
                            'max-md:fixed max-md:bottom-0 max-md:left-0 max-md:right-0 max-md:z-10 max-md:rounded-none',
                        ])}
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.page}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalCount}
                            currentPage={pagination?.page}
                            showSizeChanger={false}
                        />
                    </Row>
                ) : null}
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onDeleteCar}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this Car?</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowCarDialog(false)}
                open={showCarDialog}
                maskClosable={false}
                destroyOnClose
                width={mobileMode ? '100%' : '70%'}
                afterClose={() => {
                    form.resetFields();
                    setCarSelected(undefined);
                    onReload();
                }}
                title={
                    <div className="font-bold text-[18px]">
                        {carSelected ? 'Edit Car' : 'New Car Register'}
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowCarDialog(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="primary"
                            btnSize="sm"
                            onClick={onSaveCar}
                        >
                            Save
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <CarForm
                        carData={carSelected}
                        form={form}
                        setNewImageFileList={setNewImageFileList}
                    />
                </div>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowImageModal(false)}
                open={showImageModal}
                destroyOnClose
                width={'80%'}
                afterClose={() => {
                    setCarSelected(undefined);
                }}
                title={
                    <div className="font-bold text-[18px]">
                        <NameOfCar car={carSelected} />
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowImageModal(false)}
                        >
                            Close
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <div
                        className={classNames([
                            'grid grid-cols-1 flex-wrap gap-[20px]',
                        ])}
                    >
                        <ImageListControl
                            imageList={imageList}
                            setImageList={setImageList}
                            isMobileMode={mobileMode}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                open={openViewExcelData}
                width={'100%'}
                title={
                    <div className="flex gap-2">
                        <span className="font-normal text-[18px]">
                            Excel File:
                        </span>
                        <span className="font-bold text-[18px] underline">
                            {excelFileSelected?.name}
                        </span>
                    </div>
                }
                onCancel={() => setOpenViewExcelData(false)}
                onOk={() => onSaveImportCar()}
                afterOpenChange={open => {
                    if (!open) {
                        setExcelFileSelected(null);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                    }
                }}
                destroyOnClose
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                        <ExcelUpload
                            file={excelFileSelected}
                            setExcelData={setCarImportData}
                        />
                    </div>
                </div>
            </Modal>
            <Drawer
                title={<div>Images</div>}
                onClose={() => setShowImageDrawerModal(false)}
                open={showImageDrawerModal}
                destroyOnClose
                zIndex={1000}
                width="100%"
                height={'80%'}
                afterOpenChange={open => {
                    if (!open) {
                        setCarSelected(undefined);
                    }
                }}
                placement="bottom"
                classNames={{
                    body: 'body-edit-table-drawer',
                }}
            >
                <ImageListControl
                    imageList={imageList}
                    setImageList={setImageList}
                    isMobileMode={mobileMode}
                />
            </Drawer>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
    onCreateNew: () => void;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword, onCreateNew }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
            <div className="flex justify-center">
                <span>
                    <AutoTrackButton
                        btnType="outline"
                        btnSize="sm"
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={() => onCreateNew()}
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>Add a car</span>
                        </div>
                    </AutoTrackButton>
                </span>
            </div>
        </div>
    );
};
