import {
    Drawer,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Collapse,
    CollapseProps,
    Tag,
} from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import React from 'react';
import classNames from 'classnames';
import { ICSort, ICNoneData, ICReportSearch } from '../../icons';
import { SortOrder } from 'antd/lib/table/interface';
import { useSortData } from '.';
import styled from 'styled-components';
import { removeUndefinedAttribute } from '../../utils';
import dayjs from 'dayjs';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../hooks/useNotification';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';
import { AutoTrackButton, IconButton } from '../../components/button';
import { AutoTrackPagination } from '../../components/pagination';
import { AutoTrackTooltip } from '../../components/tooltip';
import {
    PriorityColorMapping,
    PriorityOptionsMapping,
    StatusColorMapping,
    StatusOptionsMapping,
    TCar,
    TCarInService,
} from '../start-work-order/type';
import { setLoading } from '../../redux/slices/appInfo';
import { getImageByLicensePlate } from '../../api/image-service';
import debouce from 'lodash/debounce';
import { useScreenSize } from '../../hooks/useScreenSize';
import { TImage } from '../../api/image-service/type';
import { ImageListControl } from '../cars/image-list';
import { MobileListCarPage } from '../cars/mobile-list';
import { loadServicesByCar } from '../../api/car-in-service';
import { CarServices } from './car-services';
import { CarServicesMobile } from './car-services-mobile';
import { NameOfCar } from '../components/name-of-car';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }

    .ant-picker.ant-picker-range {
        height: 44px;
    }
`;

const WrapperInput = styled.div`
    @media (max-width: 768px) {
    .ant-input-group-wrapper {
        width: 100% !important;
    }
`;

const WrapperCollapse = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    .ant-collapse-content-box {
        padding: 0 !important;
    }
`;

type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type ReportPageListProps = {
    carList: TCar[];
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    filterParams: SearchQueryParams;
    onFilterData: (payload: SearchQueryParams) => void;
};

const KeywordContext = React.createContext<string>('');

export const ReportPageList: React.FC<ReportPageListProps> = ({
    carList,
    keyword,
    pagination,
    onChangePaging,
    filterParams,
    onFilterData,
}) => {
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>(
        undefined
    );
    const [imageList, setImageList] = React.useState<TImage[]>([]);
    const [showImageModal, setShowImageModal] = React.useState(false);
    const [showImageDrawerModal, setShowImageDrawerModal] =
        React.useState(false);

    const [isShowServices, setIsShowServices] = React.useState(false);
    const [carInServices, setCarInServices] = React.useState<TCarInService[]>(
        []
    );

    // handle search keyword
    const [searchColumn, setSearchColumn] = React.useState<string>('Make');
    const [searchWord, setSearchWord] = React.useState<string | undefined>('');
    const selectBeforeOptions = [
        'Make',
        'Model',
        'Year',
        'Color',
        'VIN',
        'LicensePlate',
    ];
    const onSearchData = React.useCallback(
        async (searchWord: string | undefined, searchColumnTemp?: string) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: filterParams?.pageSize || 10,
                    searchWord: searchWord,
                    sortBy: Direction.ASC,
                    searchColum: searchColumnTemp || searchColumn,
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                onFilterData(newPayload);
            } catch (error) {
                // Handle error
            }
        },
        [filterParams, searchColumn]
    );

    const handleSearchData = (value: string | undefined) => {
        onSearchKeyword(value);
        setSearchWord(value);
    };

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchData, 600);
    }, [onSearchData]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        };
    }, [onSearchKeyword]);
    // end handle search keyword

    const onViewImages = React.useCallback(
        async (licensePlate: string) => {
            try {
                dispatch(setLoading(true));
                const result = await getImageByLicensePlate(licensePlate);
                if (result.status === 200) {
                    if (result.data?.length > 0) {
                        setImageList(result.data);
                        if (mobileMode) {
                            setShowImageDrawerModal(true);
                        } else {
                            setShowImageModal(true);
                        }
                    } else {
                        setImageList([]);
                        showSuccess('Get images', 'No images found');
                    }
                }
            } catch (error: any) {
                dispatch(setLoading(false));
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Error occurred while getting images';
                showError('Get images failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showSuccess, showError, mobileMode]
    );

    const onViewServices = React.useCallback(
        async (carTemp: TCar) => {
            // handle view services
            if (!carTemp?.carId) {
                return;
            }
            try {
                setCarSelected(carTemp);
                dispatch(setLoading(true));
                const result = await loadServicesByCar(carTemp.carId);
                setCarInServices(result.data);
                setIsShowServices(true);
            } catch (error) {
                showError('Error', 'Failed to load services');
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showError]
    );

    const columns: ColumnsType<TCar> = [
        {
            title: 'Make',
            dataIndex: 'make',
            field: 'make',
            key: 'make',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            field: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            field: 'year',
            key: 'year',
            sorter: true,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            field: 'color',
            key: 'color',
            sorter: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            field: 'vin',
            key: 'vin',
            sorter: true,
        },
        {
            title: 'License Plate',
            dataIndex: 'licensePlate',
            field: 'licensePlate',
            key: 'licensePlate',
            sorter: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            field: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (val: string) => {
                const newDate = dayjs(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return newDate;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'carId',
            key: 'carId',
            width: '40px',
            render: (carId: string, data: TCar) => {
                return (
                    <ActionMenu>
                        <AutoTrackTooltip
                            content="Report"
                            placement="top"
                            childNode={
                                <IconButton
                                    icon={<ICReportSearch />}
                                    btnType="tertiary"
                                    onClick={() => {
                                        onViewServices(data);
                                    }}
                                />
                            }
                        />
                        <AutoTrackTooltip
                            content="Receipts Images"
                            placement="top"
                            childNode={
                                <IconButton
                                    icon={<ICMaterialSymbol icon="image" />}
                                    btnType="tertiary"
                                    onClick={() => {
                                        setCarSelected(data);
                                        onViewImages(data.licensePlate);
                                    }}
                                />
                            }
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TCar>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    const renderTotalServices = React.useMemo(() => {
        const totalServices = carInServices?.length || 0;
        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted font-normal">Total:</span>
                    <span className="font-bold text-primary">
                        {totalServices}
                    </span>
                    <span className="text-muted font-normal">work orders</span>
                </div>
            </div>
        );
    }, [carInServices]);

    const parseCollapseProps = React.useMemo(() => {
        if (!carInServices) {
            return [];
        }
        const renderTotalDuration = (item: TCarInService) => {
            const durationInMinutes = item?.services?.reduce(
                (acc, service) => acc + (service?.durationInMinutes || 0),
                0
            ) as number;
            const countDuration =
                durationInMinutes && durationInMinutes < 60
                    ? durationInMinutes
                    : durationInMinutes
                      ? (durationInMinutes / 60).toFixed(2)
                      : 0;
            const renderText = durationInMinutes < 60 ? 'minutes' : 'hours';
            return (
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex items-center gap-2">
                        <span className="font-bold">
                            {countDuration} {renderText}
                        </span>
                    </div>
                </div>
            );
        };
        const renderHeader = (item: TCarInService) => {
            return (
                <div className="flex max-md:flex-col max-md:justify-start max-md:items-start flex-row items-center justify-between flex-wrap w-full gap-2">
                    <div className="flex items-center gap-2">
                        <span className="text-muted font-normal">
                            Services:
                        </span>
                        <span className="font-bold text-primary">
                            {item?.services?.length}
                        </span>
                        {item.priority && (
                            <div className="flex items-center gap-2">
                                <span>
                                    <Tag
                                        color={
                                            PriorityColorMapping[item.priority]
                                        }
                                    >
                                        {PriorityOptionsMapping[item.priority]}
                                    </Tag>
                                </span>
                            </div>
                        )}
                        {item.status && (
                            <div className="flex items-center gap-2">
                                <span>
                                    <Tag
                                        color={StatusColorMapping[item.status]}
                                    >
                                        {StatusOptionsMapping[item.status]}
                                    </Tag>
                                </span>
                            </div>
                        )}
                        {item?.completedBy && (
                            <span className="max-md:hidden">|</span>
                        )}
                        {item?.completedBy && (
                            <div className="max-md:hidden flex items-center gap-2">
                                <span className="text-muted font-normal">
                                    Completed:
                                </span>
                                <span className="font-bold">
                                    {dayjs(item?.completedBy).format(
                                        'YYYY-MM-DD HH:mm'
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                    {item?.completedBy && (
                        <div className="max-md:flex hidden items-center gap-2">
                            <span className="text-muted font-normal">
                                Completed:
                            </span>
                            <span className="font-bold">
                                {dayjs(item?.completedBy).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </span>
                        </div>
                    )}
                    <div className="flex items-center gap-2">
                        <span className="text-muted font-normal">
                            Created at:
                        </span>
                        <span className="font-bold">
                            {dayjs(item?.createdAt).format('YYYY-MM-DD HH:mm')}
                        </span>
                    </div>
                    <div className="max-md:flex hidden items-center gap-2">
                        <span className="text-muted font-normal">
                            Total Duration:
                        </span>
                        <span className="font-bold">
                            {renderTotalDuration(item)}
                        </span>
                    </div>
                </div>
            );
        };
        return carInServices.map((item: TCarInService) => {
            return {
                key: item.carInServiceId,
                label: renderHeader(item),
                children: (
                    <div>
                        {!mobileMode && <CarServices task={item} />}
                        {mobileMode && <CarServicesMobile task={item} />}
                    </div>
                ),
            };
        }) as CollapseProps['items'];
    }, [carInServices]);

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex w-full px-[12px] py-[20px] shadow-e-03 max-md:justify-center items-center max-md:flex-wrap gap-3 justify-between bg-white rounded-[12px]">
                <div className="flex gap-3 items-center justify-center w-full flex-wrap">
                    <WrapperInput className="flex items-center w-full gap-2">
                        <Input
                            placeholder="Search by ..."
                            value={searchWord}
                            onChange={e => handleSearchData(e.target.value)}
                            style={{
                                width: '420px',
                            }}
                            addonBefore={
                                <>
                                    <Select
                                        className="select-before"
                                        defaultValue="Make"
                                        value={searchColumn}
                                        style={{ width: 122 }}
                                        onChange={(value: string) => {
                                            setSearchColumn(value);
                                            onSearchData(searchWord, value);
                                        }}
                                    >
                                        {selectBeforeOptions.map(
                                            (item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </>
                            }
                            size="large"
                        />
                    </WrapperInput>
                </div>
            </div>
            <div
                className={classNames([
                    'flex flex-col w-full h-full rounded-[12px] overflow-hidden shadow-e-03',
                    {
                        'bg-white': !mobileMode,
                    },
                ])}
            >
                <KeywordContext.Provider value={keyword ?? ''}>
                    {mobileMode ? (
                        <MobileListCarPage
                            carList={carList}
                            onViewImages={onViewImages}
                            onViewServices={onViewServices}
                        />
                    ) : (
                        <Table
                            key="car-list"
                            className={classNames('white-header w-full')}
                            columns={columns}
                            dataSource={carList}
                            rowClassName="cursor-pointer data-row"
                            rowKey="carId"
                            scroll={{ x: 'max-content' }}
                            locale={{
                                emptyText: <EmptyTable keyword={keyword} />,
                            }}
                            pagination={false}
                        />
                    )}
                </KeywordContext.Provider>
                {pagination?.totalPage && pagination?.totalPage > 1 ? (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className={classNames([
                            'grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]',
                            'max-md:fixed max-md:bottom-0 max-md:left-0 max-md:right-0 max-md:z-10 max-md:rounded-none',
                        ])}
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.page}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalCount}
                            currentPage={pagination?.page}
                            showSizeChanger={false}
                        />
                    </Row>
                ) : null}
            </div>
            <Drawer
                title={
                    <div className="flex items-center justify-between gap-3">
                        <div className="flex flex-col gap-2">
                            <NameOfCar car={carSelected} />
                            <div>{renderTotalServices}</div>
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => setIsShowServices(false)}
                        >
                            <AutoTrackButton btnSize="sm" btnType="sub_danger">
                                Close
                            </AutoTrackButton>
                        </div>
                    </div>
                }
                onClose={() => setIsShowServices(false)}
                open={isShowServices}
                destroyOnClose
                closable={false}
                zIndex={1000}
                width={'100%'}
                height={'90%'}
                placement="bottom"
                classNames={{
                    body: 'body-card-services-drawer',
                }}
            >
                <WrapperCollapse>
                    <Collapse
                        items={parseCollapseProps}
                        expandIcon={({ isActive }) => (
                            <ICMaterialSymbol
                                icon={isActive ? 'expand_less' : 'expand_more'}
                            />
                        )}
                    />
                </WrapperCollapse>
            </Drawer>

            <Modal
                centered
                onCancel={() => setShowImageModal(false)}
                open={showImageModal}
                destroyOnClose
                width={'80%'}
                afterClose={() => {
                    setCarSelected(undefined);
                }}
                title={
                    <div className="font-bold text-[18px]">
                        <NameOfCar car={carSelected} />
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowImageModal(false)}
                        >
                            Close
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <div
                        className={classNames([
                            'grid grid-cols-1 flex-wrap gap-[20px]',
                        ])}
                    >
                        <ImageListControl
                            imageList={imageList}
                            setImageList={setImageList}
                            isMobileMode={mobileMode}
                        />
                    </div>
                </div>
            </Modal>
            <Drawer
                title={<div>Images</div>}
                onClose={() => setShowImageDrawerModal(false)}
                open={showImageDrawerModal}
                destroyOnClose
                zIndex={1000}
                width="100%"
                height={'80%'}
                afterOpenChange={open => {
                    if (!open) {
                        setCarSelected(undefined);
                    }
                }}
                placement="bottom"
                classNames={{
                    body: 'body-edit-table-drawer',
                }}
            >
                <ImageListControl
                    imageList={imageList}
                    setImageList={setImageList}
                    isMobileMode={mobileMode}
                />
            </Drawer>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
        </div>
    );
};
