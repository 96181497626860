import React from 'react';
import { DatePicker, Form, FormInstance, Select } from 'antd';
import { renderColorOptions } from '../../../utils';
import { AutoTrackForm } from '../../../components/form/form';
import { AutoTrackInput } from '../../../components/form/input';
import { CameraOCR } from '../camera-ocr';
import { ScannerType } from '../../add-receipts/components/scanner-overlay';
import styled from 'styled-components';

const Wrapper = styled.div`
    @media (max-width: 768px) {
        .ant-form-item .ant-form-item-label {
            flex: 0 0 100% !important;
            padding-bottom: 0px !important;
        }
    }
`;

type AddLicensePlateProps = {
    addLicensePlateForm: FormInstance<any>;
};

export const AddLicensePlate: React.FC<AddLicensePlateProps> = ({
    addLicensePlateForm,
}) => {
    const colorOptions = renderColorOptions();
    const [vinCar, setVinCar] = React.useState<string | undefined>(undefined);
    const [licensePlateCar, setLicensePlateCar] = React.useState<
        string | undefined
    >(undefined);

    const onSetVin = (val: any) => {
        if (val) {
            setVinCar(val);
            addLicensePlateForm.setFieldsValue({ vin: val });
        }
    };

    const onSetLicensePlate = (val: any) => {
        if (val) {
            setLicensePlateCar(val);
            addLicensePlateForm.setFieldsValue({ licensePlate: val });
        }
    };

    return (
        <div className="bg-white rounded-[8px]">
            <div className="mb-[24px] flex flex-col gap-[8px]">
                <div className="text-heading-6-bold text-high-em">
                    Add license plate
                </div>
                <div className="text-med-em text-body">
                    Please enter the license plate number of the vehicle you
                    want to add to the system
                </div>
            </div>
            <Wrapper className="flex w-full px-2 py-3 max-md:pt-0">
                <AutoTrackForm
                    form={addLicensePlateForm}
                    layout="vertical"
                    style={{ width: '100%' }}
                    labelCol={{ span: 10 }}
                    childNode={
                        <div className="flex flex-col gap-3 w-full">
                            <Form.Item
                                name="make"
                                label="Make"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the name!',
                                    },
                                ]}
                            >
                                <AutoTrackInput isRequired={true} />
                            </Form.Item>
                            <Form.Item
                                name="model"
                                label="Model"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the model!',
                                    },
                                ]}
                            >
                                <AutoTrackInput isRequired={true} />
                            </Form.Item>
                            <Form.Item
                                name="year"
                                label="Year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the year!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    picker="year"
                                    size="large"
                                    style={{ width: '100%' }}
                                    type="year"
                                />
                            </Form.Item>
                            <Form.Item
                                name="color"
                                label="Color"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the color!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Color"
                                    size="large"
                                    options={colorOptions}
                                />
                            </Form.Item>
                            <Form.Item
                                name="vin"
                                label="VIN"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the VIN!',
                                    },
                                ]}
                            >
                                <div className="grid grid-cols-[1fr_50px] items-center justify-center gap-2">
                                    <AutoTrackInput
                                        value={vinCar}
                                        isRequired={true}
                                        onChange={e =>
                                            setVinCar(e.target.value)
                                        }
                                    />
                                    <div className="max-md:p-0 max-md:w-full">
                                        <CameraOCR
                                            scannerType={ScannerType.VIN}
                                            onSelectedTextImage={onSetVin}
                                        />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="licensePlate"
                                label="License Plate"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input the license plate!',
                                    },
                                ]}
                            >
                                <div className="grid grid-cols-[1fr_50px] items-center justify-center gap-2">
                                    <AutoTrackInput
                                        value={licensePlateCar}
                                        isRequired={true}
                                        onChange={e =>
                                            setLicensePlateCar(e.target.value)
                                        }
                                    />
                                    <div className="max-md:p-0 max-md:w-full">
                                        <CameraOCR
                                            scannerType={ScannerType.PLATE}
                                            onSelectedTextImage={
                                                onSetLicensePlate
                                            }
                                        />
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    }
                />
            </Wrapper>
        </div>
    );
};
