import React, { useState } from 'react';
import { Table } from 'antd';
import * as XLSX from 'xlsx';
import classNames from 'classnames';
import { parse, isValid } from 'date-fns';

type ExcelUploadProps = {
    file: File | null;
    setExcelData: (data: any[]) => void;
};

export const ExcelUpload: React.FC<ExcelUploadProps> = ({
    file,
    setExcelData,
}) => {
    const [data, setData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [viewError, setViewError] = useState(false);

    const loadExcelFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = e => {
            const binaryStr = e.target?.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 0) {
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1);

                // Filter out empty rows
                const validRows = (rows as any[][]).filter(row =>
                    row.some(
                        cell =>
                            cell !== null && cell !== undefined && cell !== ''
                    )
                );

                const tableColumns: Array<{
                    title: string;
                    dataIndex: string;
                    key: string;
                    width?: number;
                    fixed?: string;
                }> = headers.map((header: string) => ({
                    title: header,
                    dataIndex: header,
                    key: header,
                }));
                tableColumns.unshift({
                    title: 'No',
                    dataIndex: 'no',
                    key: 'no',
                    width: 50,
                    fixed: 'left',
                });
                tableColumns.push({
                    title: 'Error',
                    dataIndex: 'errorMessage',
                    key: 'errorMessage',
                });

                const tableData = validRows.map((row: any[], index: number) => {
                    const rowData: any = {};
                    headers.forEach((header: string, i: number) => {
                        rowData[header] = row[i];
                    });
                    return { key: index, ...rowData };
                });

                setColumns(tableColumns);
                setData(tableData);
                setExcelData(tableData);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    React.useEffect(() => {
        if (file) {
            loadExcelFile(file);
        }
    }, [file]);

    const validationDateFormats = (date: string) => {
        if (!date) return false;
        const dateFormats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd/MM/yyyy'];
        return dateFormats.some(format => {
            const parsedDate = parse(date.toString(), format, new Date());
            return isValid(parsedDate);
        });
    };

    const validationCostIsNumber = (cost: string) => {
        if (!cost) return false;
        const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/;
        return regex.test(cost);
    };

    const dataSourceTable = React.useMemo(() => {
        return data
            .map((item, index) => {
                const purchaseDate = item['PurchaseDate'];
                if (purchaseDate) {
                    if (!validationDateFormats(purchaseDate)) {
                        item['isValid'] = true;
                        item['errorMessage'] =
                            'Invalid date format on Purchase Date';
                    }
                }
                const registration = item['Registration'];
                if (registration) {
                    if (!validationDateFormats(registration)) {
                        item['isValid'] = true;
                        if (item['errorMessage']) {
                            item['errorMessage'] =
                                item['errorMessage'] +
                                ', Invalid date format on Registration';
                        } else {
                            item['errorMessage'] =
                                'Invalid date format on Registration';
                        }
                    }
                }
                const cost = item['Cost'];
                if (cost) {
                    if (!validationCostIsNumber(cost)) {
                        item['isValid'] = true;
                        if (item['errorMessage']) {
                            item['errorMessage'] =
                                item['errorMessage'] +
                                ', Invalid cost format on Cost';
                        } else {
                            item['errorMessage'] =
                                'Invalid cost format on Cost';
                        }
                    }
                }
                return {
                    ...item,
                    key: index,
                    no: index + 1,
                };
            })
            .filter(item => {
                if (viewError) {
                    return item.isValid;
                }
                return true;
            });
    }, [data, viewError]);

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col items-center justify-end w-full mb-4">
                <div className="flex justify-start items-center w-full gap-2">
                    <div className="font-semibold">Total: {data.length}</div>
                    <div>|</div>
                    <div className="font-semibold text-success-500">
                        Success:{' '}
                        {dataSourceTable?.filter(item => !item.isValid).length}
                    </div>
                    <div>|</div>
                    <div
                        onClick={() => setViewError(!viewError)}
                        className="font-semibold text-sy-dark-alert underline cursor-pointer"
                    >
                        Error:{' '}
                        {dataSourceTable.filter(item => item.isValid).length}
                    </div>
                </div>
            </div>
            <div className="flex w-full">
                <Table
                    className={classNames('white-header w-full')}
                    columns={columns}
                    dataSource={dataSourceTable}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </div>
    );
};
