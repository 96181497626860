import React from 'react';

import {
    EStatusOfService,
    TCarInService,
    TServiceDetail,
} from '../../start-work-order/type';
import { TaskItemPage } from '../../all-work-orders/task-item';
import styled from 'styled-components';
import { Spin } from 'antd';

const Wrapper = styled.div`
    overflow-y: auto;
    &.infinite-scroll-container > div:first-child {
        width: 100% !important;
    }
`;

type TaskItemPageProps = {
    taskList: TCarInService[];
    hasLoadMore: boolean;
    mobileMode: boolean;
    loading: boolean;
    setCarInServiceSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
    setOpenEditTask: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        taskSelected: TCarInService | undefined,
        isForceReload: boolean
    ) => void;
    onLoadMore: () => void;
    onReloadData: () => void;
};

export const TaskListPage: React.FC<TaskItemPageProps> = ({
    taskList,
    loading,
    hasLoadMore,
    mobileMode,
    setCarInServiceSelected,
    setOpenEditTask,
    onUpdateStatus,
    onLoadMore,
    onReloadData,
}) => {
    const contentStyle: React.CSSProperties = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
    };
    const contentLoading = <div style={contentStyle} />;

    const scrollableDivRef = React.useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (!scrollableDivRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } =
            scrollableDivRef.current;
        if (
            scrollHeight - scrollTop <= clientHeight + 1 &&
            hasLoadMore &&
            !loading
        ) {
            onLoadMore();
        }
    };

    React.useEffect(() => {
        const scrollableDiv = scrollableDivRef.current;
        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll);
            return () => {
                scrollableDiv.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    return (
        <Wrapper
            ref={scrollableDivRef}
            className="flex w-full h-full max-md:max-h-screen infinite-scroll-container"
        >
            <div className="flex flex-col h-full w-full max-md:pt-0 max-md:px-0 px-[1.5rem] pt-[1rem] pb-[5px]">
                <div className="grid max-md:grid-cols-1 grid-cols-3 gap-[15px] flex-wrap pb-3">
                    {taskList.map((task, index) => (
                        <TaskItemPage
                            key={index}
                            task={task}
                            mobileMode={mobileMode}
                            setCarInServiceSelected={setCarInServiceSelected}
                            setOpenEditTask={setOpenEditTask}
                            onUpdateStatus={onUpdateStatus}
                            onReload={onReloadData}
                        />
                    ))}
                </div>
                {loading && (
                    <div className="text-center">
                        <Spin tip="Loading...">{contentLoading}</Spin>
                    </div>
                )}
            </div>
        </Wrapper>
    );
};
