import { IconProps } from './type';

export const ICExcel: React.FC<IconProps> = ({ width = 24, height = 24 }) => {
    return (
        <svg
            width={width}
            height={height}
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path d="m2.859 2.877 12.57-1.795a.5.5 0 0 1 .571.494v20.848a.5.5 0 0 1-.57.494L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM4 4.735v14.53l10 1.429V3.306L4 4.735zM17 19h3V5h-3V3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4v-2zm-6.8-7 2.8 4h-2.4L9 13.714 7.4 16H5l2.8-4L5 8h2.4L9 10.286 10.6 8H13l-2.8 4z" />
        </svg>
    );
};
