import {
    DatePicker,
    Drawer,
    Form,
    Input,
    Select,
    Image,
    Radio,
    Modal,
} from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TCar } from '../start-work-order/type';
import { AutoTrackForm } from '../../components/form/form';
import { AutoTrackInput } from '../../components/form/input';
import { renderColorOptions } from '../../utils';
import { FileUpload } from '../add-receipts/components/FileUpload';
import { CameraControl } from '../add-receipts/components/camera-control';
import { useScreenSize } from '../../hooks/useScreenSize';
import Webcam from 'react-webcam';
import { ScannerType } from '../add-receipts/components/scanner-overlay';
import { ICClose } from '../../icons';
import { readFileAsDataURL } from '../../utils/fileUtils';
import { TImage } from '../../api/image-service/type';
import { getDownloadURL, ref } from 'firebase/storage';
import { storageFirebase } from '../../firebase/firebaseConfig';
import { setLoading } from '../../redux/slices/appInfo';
import { useDispatch } from 'react-redux';
import { deleteCarImage, getImageCarByCarId } from '../../api/image-service';
import { setDefaultImageCar } from '../../api/setting/cars';
import { useNotification } from '../../hooks/useNotification';
import { AutoTrackButton } from '../../components/button';

dayjs.extend(customParseFormat);

const Wrapper = styled.div`
    .ant-row {
        align-items: center;
    }
    .ant-form-item-has-error {
        .ant-row {
            align-items: flex-start;
        }
    }
    @media (max-width: 768px) {
        .ant-form-item .ant-form-item-label {
            flex: 0 0 100% !important;
            padding-bottom: 0px;
        }
    }
`;

const WrapperDocuments = styled.div`
    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
        height: 50px;
        width: 50px;
        .ant-upload.ant-upload-select {
            border-color: #7357ff !important;
            width: 100%;
            height: 100%;
            padding: 5px;
        }
    }
`;

const DrawerBottom = styled(Drawer)`
    .ant-drawer-body {
        padding: 0;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
`;

type TImageData = {
    id: string;
    image: string;
    imageFile?: File;

    imageId?: number;
    imageUrl?: string;
    fileName?: string;
    createdAt?: string;
    url?: string;
    carImageId?: string | number;
};

type CarFormProps = {
    carData: TCar | undefined;
    form: FormInstance;
    setNewImageFileList: React.Dispatch<React.SetStateAction<File[]>>;
};
export const CarForm: React.FC<CarFormProps> = ({
    carData,
    form,
    setNewImageFileList,
}) => {
    const dispatch = useDispatch();
    const { showError, showSuccess } = useNotification();
    const colorOptions = renderColorOptions();
    const webcamRef = React.useRef<Webcam>(null);
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const [hasImageCompression, setHasImageCompression] = React.useState(false);
    const [openCamera, setOpenCamera] = React.useState(false);
    const [imageList, setImageList] = React.useState<TImageData[]>([]);
    const [defaultImage, setDefaultImage] = React.useState<
        number | string | undefined
    >(undefined);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [imageSelected, setImageSelected] = React.useState<TImageData | null>(
        null
    );

    const renderUUID = () => {
        return Math.floor(Math.random() * 1000000).toString();
    };

    const onSelectedImage = async (image: File) => {
        try {
            if (image instanceof File) {
                setNewImageFileList(pre => [...pre, image]);
                const url = await readFileAsDataURL(image);
                setImageList(prev => [
                    ...prev,
                    { id: renderUUID(), image: url, imageFile: image },
                ]);
            }
        } catch (error) {
            // TODO: handle error
        }
    };

    const convertImageUrl = async (img: TImageData) => {
        let url = '';
        try {
            const imageRef = ref(storageFirebase, img.imageUrl);
            url = await getDownloadURL(imageRef);
            return url;
        } catch (error) {
            // Handle error
        }
        return url;
    };

    const parseImageUrlList = async (imageList: TImageData[]) => {
        const result = await Promise.all(
            imageList.map(async img => {
                const url = await convertImageUrl(img);
                if (!url) {
                    return img;
                }
                return {
                    ...img,
                    image: url,
                };
            })
        );
        return result;
    };

    const fetchImageList = async (carId: number | string) => {
        try {
            dispatch(setLoading(true));
            const response = await getImageCarByCarId(carId);
            if (!response.data) {
                return;
            }
            const imagesTemp: TImageData[] = response.data.map(
                (item: TImage) => {
                    return {
                        ...item,
                        id: renderUUID(),
                        image: item.imageUrl,
                    };
                }
            );
            const result = await parseImageUrlList(imagesTemp);
            setImageList(result.filter(item => item.url));
        } catch (error) {
            // TODO: handle error
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onSetDefaultCarImage = async (
        carImageId: string | number | undefined
    ) => {
        try {
            if (!carData?.carId || !carImageId) {
                return;
            }
            dispatch(setLoading(true));
            await setDefaultImageCar(carData?.carId ?? '', carImageId);
        } catch (error) {
            showError('Error', 'Failed to set default image');
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onRemoveImage = React.useCallback(async () => {
        try {
            if (!imageSelected) {
                return;
            }
            if (!imageSelected?.carImageId) {
                return;
            }
            dispatch(setLoading(true));
            const carImageId = imageSelected?.carImageId.toString();
            await deleteCarImage(carImageId);
            showSuccess('Success', 'Image removed successfully');
            setShowDeleteModal(false);
            setImageList(prev =>
                prev.filter(
                    item => item.carImageId !== imageSelected.carImageId
                )
            );
        } catch (error) {
            showError('Error', 'Failed to remove image');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showSuccess, showError, imageSelected]);

    const showConfirmDeleteModal = (image: TImageData) => {
        if (!image.carImageId) {
            setImageList(prev => prev.filter(item => item.id !== image.id));
            return;
        }
        setImageSelected(image);
        setShowDeleteModal(true);
    };

    const renderImages = React.useMemo(() => {
        if (imageList.length === 0) {
            return null;
        }
        return (
            <div className="flex flex-wrap gap-[30px] shadow p-[20px] w-full bg-white border border-gray-300 rounded-[10px]">
                <Radio.Group
                    value={defaultImage}
                    style={{
                        display: 'flex',
                        gap: '30px',
                    }}
                >
                    {imageList?.map((item, index) => {
                        const isSelected =
                            defaultImage === item.carImageId &&
                            item.carImageId !== undefined;
                        return (
                            <div
                                key={index}
                                className="flex items-center justify-center relative rounded-[10px]"
                            >
                                <ImageContainer>
                                    <Image
                                        width="70px"
                                        height="70px"
                                        key={item.id}
                                        src={item.image}
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: '8px',
                                        }}
                                        alt={`image-${index}`}
                                    />
                                    {item.carImageId && (
                                        <Radio
                                            value={item.carImageId}
                                            checked={isSelected}
                                            onChange={() => {
                                                setDefaultImage(
                                                    item.carImageId
                                                );
                                                onSetDefaultCarImage(
                                                    item.carImageId
                                                );
                                            }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                margin: 0,
                                                padding: 0,
                                                gap: '5px',
                                            }}
                                        >
                                            Default
                                        </Radio>
                                    )}
                                </ImageContainer>
                                <div
                                    className="flex items-center justify-center p-[5px] absolute top-[-10px] left-[60px] cursor-pointer bg-red-400 h-[25px] w-[25px] rounded-[50%]"
                                    onClick={() => {
                                        showConfirmDeleteModal(item);
                                    }}
                                >
                                    <ICClose
                                        width={20}
                                        height={20}
                                        fill="white"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Radio.Group>
            </div>
        );
    }, [imageList, defaultImage, carData]);

    const onCompletedCapture = (image: string, imageFile: File) => {
        setImageList(prev => [...prev, { id: renderUUID(), image, imageFile }]);
        setNewImageFileList(pre => [...pre, imageFile]);
        setOpenCamera(false);
    };

    const childForm = React.useMemo(() => {
        const defaultFieldsCar = (
            <div className="grid gap-[10px] grid-cols-[1fr] max-md:p-[3px]">
                <Form.Item
                    name="make"
                    label="Make"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name!',
                        },
                    ]}
                >
                    <AutoTrackInput isRequired={true} />
                </Form.Item>
                <Form.Item
                    name="model"
                    label="Model"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the model!',
                        },
                    ]}
                >
                    <AutoTrackInput isRequired={true} />
                </Form.Item>
                <Form.Item
                    name="year"
                    label="Year"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the year!',
                        },
                    ]}
                >
                    <DatePicker
                        picker="year"
                        size="large"
                        style={{ width: '100%' }}
                        type="year"
                    />
                </Form.Item>
                <Form.Item
                    name="color"
                    label="Color"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the color!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        size="large"
                        placeholder="Color"
                        options={colorOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="vin"
                    label="VIN"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the VIN!',
                        },
                    ]}
                >
                    <AutoTrackInput isRequired={true} />
                </Form.Item>
                <Form.Item
                    name="licensePlate"
                    label="License Plate"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the license plate!',
                        },
                    ]}
                >
                    <AutoTrackInput isRequired={true} />
                </Form.Item>
                <WrapperDocuments className="flex flex-col w-full gap-[5px]">
                    <label>Images</label>
                    <FileUpload
                        setOpenCamera={() => {
                            setHasImageCompression(true);
                            setOpenCamera(true);
                        }}
                        onSelectedImage={(file: File) => {
                            setTimeout(() => {
                                onSelectedImage(file);
                            }, 1);
                        }}
                    />
                </WrapperDocuments>
                {renderImages}
            </div>
        );
        if (carData?.carId) {
            return (
                <div className="grid gap-[10px] grid-cols-[1fr]">
                    {defaultFieldsCar}
                    <div className="flex gap-3 flex-col rounded-md border border-gray-300 p-[15px]">
                        <div>
                            <h3 className="text-md underline font-semibold">
                                Additional Information
                            </h3>
                        </div>
                        <Form.Item name="bouncie" label="Bouncie">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="cost" label="Cost">
                            <AutoTrackInput type="number" />
                        </Form.Item>
                        <Form.Item name="insurance" label="Insurance">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="mileage" label="Mileage">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="ntta" label="NTTA">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="owner" label="Owner">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="purchaseDate" label="Purchase Date">
                            <DatePicker
                                size="large"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item name="registration" label="Registration">
                            <DatePicker
                                size="large"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item name="keys" label="Keys">
                            <AutoTrackInput />
                        </Form.Item>
                        <Form.Item name="notes" label="Notes">
                            <Input.TextArea />
                        </Form.Item>
                    </div>
                </div>
            );
        }

        return defaultFieldsCar;
    }, [
        carData,
        onSelectedImage,
        imageList,
        setHasImageCompression,
        setOpenCamera,
    ]);

    React.useEffect(() => {
        if (carData) {
            const year = `${carData.year}-01-01`;
            const purchaseDate = carData.purchaseDate
                ? dayjs(carData.purchaseDate)
                : undefined;
            const registration = carData.registration
                ? dayjs(carData.registration)
                : undefined;
            form.setFieldsValue({
                make: carData.make,
                model: carData.model,
                year: dayjs(year),
                color: carData.color,
                vin: carData.vin,
                licensePlate: carData.licensePlate,
                bouncie: carData.bouncie,
                cost: carData.cost,
                insurance: carData.insurance,
                mileage: carData.mileage,
                ntta: carData.ntta,
                owner: carData.owner,
                purchaseDate: purchaseDate,
                registration: registration,
                keys: carData.keys ?? '',
                notes: carData.notes,
            });
            if (carData?.carId) {
                fetchImageList(carData.carId);
            }
            if (carData?.carImageId) {
                setDefaultImage(carData.carImageId);
            }
        }
    }, [carData]);

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border py-[20px] px-[5px] max-md:pt-0 max-md:px-0">
            <AutoTrackForm
                labelCol={{ flex: '130px' }}
                labelAlign="left"
                form={form}
                labelWrap
                wrapperCol={{ flex: 1 }}
                layout="horizontal"
                className="w-full"
                name="createCar"
                childNode={childForm}
            />
            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onRemoveImage}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this image?</p>
            </Modal>
            <DrawerBottom
                title="Take a photo"
                onClose={() => setOpenCamera(false)}
                open={openCamera}
                destroyOnClose
                zIndex={1000}
                width="100%"
                height="100%"
                placement="bottom"
            >
                <div className="flex h-full flex-col justify-between w-full gap-3">
                    <div className="flex w-full h-full mt-[0]">
                        <CameraControl
                            webcamRef={webcamRef}
                            isMobile={mobileMode}
                            hasImageCompression={hasImageCompression}
                            isShowScannerOverlay={false}
                            scannerType={ScannerType.PLATE}
                            onCompletedCapture={onCompletedCapture}
                        />
                    </div>
                </div>
            </DrawerBottom>
        </Wrapper>
    );
};
