import React from 'react';
import {
    StatusOfServiceColorMapping,
    StatusOfServiceStateMapping,
    TCarInService,
} from '../start-work-order/type';
import { Card, Tag } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

const WrapperContent = styled.div`
    max-height: calc(100vh - 170px);
    overflow-y: auto;
`;

type CarServicesMobileProps = {
    task: TCarInService | undefined;
};

export const CarServicesMobile: React.FC<CarServicesMobileProps> = ({
    task,
}) => {
    return (
        <div className="flex flex-col relative">
            <div className="relative flex overflow-auto max-h-full flex-col items-start gap-2 bg-white h-full w-full rounded-[8px]">
                <WrapperContent className="flex flex-col w-full gap-[15px] p-[16px]">
                    {task?.services?.map((service, index) => (
                        <Card
                            key={`${service.serviceDetailId}_${index}`}
                            className={classNames([
                                'relative flex flex-col cursor-pointer rounded-[12px] max-md:hover:ring-0 hover:ring-2 hover:ring-[var(--primaryColor)] shadow-card-shadow',
                            ])}
                        >
                            <div className="flex flex-col gap-[10px] relative">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <span className="text-lg font-bold">
                                            {service.service?.name}
                                        </span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-[80px_1fr] flex-row items-center justify-start gap-2">
                                    <span className="text-muted">Assigned</span>
                                    <span className="font-bold underline">
                                        {service.user?.username || 'N/A'}
                                    </span>
                                </div>
                                {service.serviceDetailStatus && (
                                    <div className="grid grid-cols-[80px_1fr] items-center justify-start gap-2">
                                        <span className="text-muted">
                                            Status
                                        </span>
                                        <span className="font-bold">
                                            <Tag
                                                color={
                                                    StatusOfServiceColorMapping[
                                                        service
                                                            .serviceDetailStatus
                                                    ]
                                                }
                                            >
                                                {
                                                    StatusOfServiceStateMapping[
                                                        service
                                                            .serviceDetailStatus
                                                    ]
                                                }
                                            </Tag>
                                        </span>
                                    </div>
                                )}
                                <div className="grid grid-cols-[80px_1fr] items-center justify-start gap-2">
                                    <span className="text-muted">Duration</span>
                                    <span className="font-bold">
                                        {service.durationInMinutes || 0} minutes
                                    </span>
                                </div>
                            </div>
                        </Card>
                    ))}
                </WrapperContent>
            </div>
        </div>
    );
};
