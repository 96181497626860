import { Image, Modal } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TImage } from '../../api/image-service/type';
import { setLoading } from '../../redux/slices/appInfo';
import { getDownloadURL, ref } from 'firebase/storage';
import { storageFirebase } from '../../firebase/firebaseConfig';
import { AutoTrackButton } from '../../components/button';
import { useNotification } from '../../hooks/useNotification';
import { deleteImage } from '../../api/image-service';
import styled from 'styled-components';
import { ICClose } from '../../icons';

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
`;

const Wrapper = styled.div`
    .ant-image .ant-image-mask:hover {
        border-radius: 12px;
    }
`;

type ImageListControlProps = {
    imageList: TImage[];
    setImageList: React.Dispatch<React.SetStateAction<TImage[]>>;
    isMobileMode: boolean;
};
export const ImageListControl: React.FC<ImageListControlProps> = ({
    imageList,
    setImageList,
    isMobileMode,
}) => {
    const dispatch = useDispatch();
    const { showError, showSuccess } = useNotification();
    const [imageUrlList, setImageUrlList] = React.useState<TImage[]>([]);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [imageSelected, setImageSelected] = React.useState<TImage | null>(
        null
    );

    const convertImageUrl = async (img: TImage) => {
        let url = '';
        try {
            const imageRef = ref(storageFirebase, img.imageUrl);
            url = await getDownloadURL(imageRef);
            return url;
        } catch (error) {
            // Handle error
        }
        return url;
    };

    const parseImageUrlList = async (imageList: TImage[]) => {
        const result = await Promise.all(
            imageList.map(async img => {
                const url = await convertImageUrl(img);
                if (!url) {
                    return img;
                }
                return {
                    ...img,
                    url: url,
                };
            })
        );
        return result;
    };

    React.useEffect(() => {
        if (imageList?.length > 0) {
            dispatch(setLoading(true));
            parseImageUrlList(imageList).then(data => {
                dispatch(setLoading(false));
                setImageUrlList(data.filter(item => item.url));
            });
        }
    }, [imageList]);

    const onRemoveImage = React.useCallback(async () => {
        try {
            if (!imageSelected) {
                return;
            }
            if (!imageSelected?.imageId) {
                return;
            }
            dispatch(setLoading(true));
            const imageId = imageSelected?.imageId.toString();
            await deleteImage(imageId);
            showSuccess('Success', 'Image removed successfully');
            setShowDeleteModal(false);
            setImageList(prev =>
                prev.filter(item => item.imageId !== imageSelected.imageId)
            );
        } catch (error) {
            showError('Error', 'Failed to remove image');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess, imageSelected]);

    const showConfirmDeleteModal = (image: TImage) => {
        if (!image.imageId) {
            setImageList(prev => prev.filter(item => item !== image));
            return;
        }
        setImageSelected(image);
        setShowDeleteModal(true);
    };

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-auto px-3 h-full items-start box-border p-[20px]">
            <div
                className={classNames([
                    'grid flex-wrap gap-[30px] w-full',
                    {
                        'grid-cols-1': isMobileMode,
                        'grid-cols-2': !isMobileMode,
                    },
                ])}
            >
                <Image.PreviewGroup>
                    {imageUrlList.map((img, index) => {
                        if (!img.url) {
                            return (
                                <div
                                    key={index}
                                    className={classNames([
                                        'flex justify-center items-center',
                                        'w-full h-full',
                                        'text-white text-lg font-bold',
                                        'bg-black bg-opacity-50',
                                        'rounded-lg',
                                        {
                                            'h-20': isMobileMode,
                                            'h-40': !isMobileMode,
                                        },
                                    ])}
                                >
                                    Loading...
                                </div>
                            );
                        }
                        return (
                            <ImageContainer key={index}>
                                <Image
                                    width="100%"
                                    height={isMobileMode ? 100 : 200}
                                    loading="lazy"
                                    style={{
                                        objectFit: 'cover',
                                        borderRadius: '12px',
                                        border: '1px solid var(--text-low-em)',
                                        padding: '5px',
                                    }}
                                    preview={{
                                        mask: (
                                            <div className="bg-black bg-opacity-50" />
                                        ),
                                    }}
                                    src={img.url}
                                    alt={img.fileName}
                                />
                                <div
                                    className="flex items-center justify-center p-[5px] absolute top-[10px] right-[10px] cursor-pointer bg-red-400 h-[25px] w-[25px] rounded-[50%]"
                                    onClick={() => {
                                        showConfirmDeleteModal(img);
                                    }}
                                >
                                    <ICClose
                                        width={20}
                                        height={20}
                                        fill="white"
                                    />
                                </div>
                            </ImageContainer>
                        );
                    })}
                </Image.PreviewGroup>
            </div>
            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onRemoveImage}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this image?</p>
            </Modal>
        </Wrapper>
    );
};
