import ReactDOM from 'react-dom/client';
import './tailwind.css';
import './variables.css';
import './index.css';
import './fonts.css';

// mock data
import './mock';

import { Provider } from 'react-redux';
import store from './redux/store';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import { NotFoundPage } from './pages/404';
import { Loading } from './components/loading';
import { Unauthorized } from './pages/unauthorized';
import { GeneralPage } from './pages/general';
import { AllWorkOrdersPage } from './pages/all-work-orders';
import { ConfigProvider, ThemeConfig } from 'antd';
import { LoginPage } from './pages/auth/login';
import { LayoutType } from './utils/enums';
import { StartNewWorkOrderPage } from './pages/start-work-order';
import { CarsPage } from './pages/cars';
import { ServicesPage } from './pages/services';
import { UserServicesPage } from './pages/user-service';
import { RegisterPage } from './pages/auth/register';
import { registerWorker } from './firebase/registerWorker';
import { AddReceiptsPage } from './pages/add-receipts';
import { ChangePasswordPage } from './pages/auth/change-password';
import { ReportPage } from './pages/report';
import { MyWorkOrdersPage } from './pages/work-order';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const config: ThemeConfig = {
    hashed: false,
    token: {
        fontFamily: 'Roboto, sans-serif',
        colorPrimary: '#7357FF',
    },
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/work-order" />,
    },
    {
        path: '/login',
        element: <Unauthorized page={<LoginPage />} />,
    },
    {
        path: '/register',
        element: <Unauthorized page={<RegisterPage />} />,
    },
    {
        path: '/change-password/:email',
        element: <Unauthorized page={<ChangePasswordPage />} />,
    },
    {
        path: '/all-work-orders',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<AllWorkOrdersPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'All Work Orders',
                description: 'Welcome to the All Work Orders page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/work-order',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<MyWorkOrdersPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'My Work Orders',
                description: 'Welcome to the My Work Orders page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/start-work-order',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<StartNewWorkOrderPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'New Work Order',
                description: 'Welcome to the work order page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/add-receipts',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<AddReceiptsPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Add Receipt',
                description: 'Welcome to the Add Receipt page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/cars',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<CarsPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Cars',
                description: 'Welcome to the Cars page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/services',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<ServicesPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Services',
                description: 'Welcome to the Services page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/user-service',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<UserServicesPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Users',
                description: 'Welcome to the User Services page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    // report
    {
        path: '/report',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<ReportPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Report',
                description: 'Welcome to the Report page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]);

root.render(
    <ConfigProvider theme={config}>
        <Provider store={store}>
            <div className="App">
                <RouterProvider router={router} />
                <Loading />
            </div>
        </Provider>
    </ConfigProvider>
);

// register service worker
registerWorker();
serviceWorkerRegistration.register();
