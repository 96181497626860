import { Descriptions, Row, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import {
    EStatusOfService,
    PriorityColorMapping,
    PriorityOptionsMapping,
    StatusColorMapping,
    StatusOfServiceColorMapping,
    StatusOfServiceStateMapping,
    StatusOptionsMapping,
    TCar,
    TCarInService,
    TServiceDetail,
} from '../start-work-order/type';
import classNames from 'classnames';
import { SortOrder } from 'antd/es/table/interface';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { useSortData } from '.';
import { ICNoneData, ICSort } from '../../icons';
import { IconButton } from '../../components/button';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import dayjs from 'dayjs';
import { formatNumber } from '../../utils';
import { ServicesActionsPage } from '../all-work-orders/services-actions';
import { AutoTrackPagination } from '../../components/pagination';
import { TPaging } from '../../api/car-in-service/type';
import { NameOfCar } from '../components/name-of-car';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    padding-right: 16px;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }
`;

const KeywordContext = React.createContext<string>('');

type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type TaskTablePageProps = {
    taskList: TCarInService[];
    keyword?: string;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService | undefined
    ) => void;
    setCarInServiceSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
    setOpenEditTask: React.Dispatch<React.SetStateAction<boolean>>;
    pagination: TPaging | undefined;
    onChangePaging: ({ page }: { page: number }) => void;
};

export const TaskTablePage: React.FC<TaskTablePageProps> = ({
    taskList,
    keyword,
    pagination,
    onChangePaging,
    onUpdateStatus,
    setCarInServiceSelected,
    setOpenEditTask,
}) => {
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const columns: ColumnsType<TCarInService> = [
        {
            title: 'No.',
            dataIndex: 'carInServiceId',
            key: 'carInServiceId',
            render: (value: string, data: TCarInService, index: number) => {
                return (
                    <span className="text-body text-high-em">{index + 1}</span>
                );
            },
        },
        {
            title: 'Car Name',
            dataIndex: 'car',
            field: 'car',
            key: 'car',
            sorter: true,
            render: (val: TCar) => {
                return <NameOfCar car={val} />;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (status: string) => {
                return (
                    <Tag color={StatusColorMapping[status]}>
                        {StatusOptionsMapping[status]}
                    </Tag>
                );
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: true,
            render: (priority: string) => {
                return (
                    <Tag color={PriorityColorMapping[priority]}>
                        {PriorityOptionsMapping[priority]}
                    </Tag>
                );
            },
        },
        {
            title: 'Services',
            dataIndex: 'services',
            key: 'services',
            render: (services: TServiceDetail[]) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <span className="text-muted font-13">
                            {services?.length} service(s)
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (createdAt: string) => {
                return (
                    <Tooltip
                        title={dayjs(createdAt).format('MM/DD/YYYY HH:mm:ss')}
                    >
                        <span>{dayjs(createdAt).format('MM/DD/YYYY')}</span>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'carInServiceId',
            key: 'carInServiceId',
            width: '40px',
            render: (carId: string, data: TCarInService) => {
                return (
                    <ActionMenu>
                        <IconButton
                            icon={<ICMaterialSymbol icon="edit_square" />}
                            btnType="tertiary"
                            onClick={() => {
                                setCarInServiceSelected(data);
                                setOpenEditTask(true);
                            }}
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TCarInService>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex flex-col w-full h-full bg-white rounded-[12px] overflow-hidden shadow-e-03">
                <KeywordContext.Provider value={keyword ?? ''}>
                    <Table
                        key="carInServiceId"
                        className={classNames('white-header w-full')}
                        columns={columns}
                        dataSource={taskList}
                        rowClassName="cursor-pointer data-row"
                        rowKey="carInServiceId"
                        scroll={{ x: 'max-content' }}
                        locale={{
                            emptyText: <EmptyTable keyword={keyword} />,
                        }}
                        pagination={false}
                        expandable={{
                            expandedRowRender: record => (
                                <div>
                                    <Descriptions
                                        bordered
                                        size="small"
                                        style={{ width: '100%' }}
                                        contentStyle={{
                                            fontSize: '13px',
                                            fontWeight: 400,
                                            lineHeight: '22px',
                                            backgroundColor: 'white',
                                        }}
                                        labelStyle={{
                                            backgroundColor: '#F8F8F8',
                                        }}
                                        column={{
                                            xxl: 1,
                                            xl: 1,
                                            lg: 1,
                                            md: 1,
                                            sm: 1,
                                            xs: 1,
                                        }}
                                    >
                                        {record?.services?.map(item => {
                                            return (
                                                <Descriptions.Item
                                                    label={
                                                        <div className="flex items-center justify-between gap-3">
                                                            <span className="text-body-bold text-high-em">
                                                                {
                                                                    item.service
                                                                        ?.name
                                                                }
                                                            </span>
                                                            <span>
                                                                {formatNumber(
                                                                    item.service
                                                                        ?.price ??
                                                                        0
                                                                )}
                                                            </span>
                                                        </div>
                                                    }
                                                    key={
                                                        item.service?.serviceId
                                                    }
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex w-full justify-between items-center gap-3">
                                                            <span>
                                                                {
                                                                    item.user
                                                                        ?.username
                                                                }
                                                            </span>
                                                            {item?.serviceDetailStatus && (
                                                                <span>
                                                                    <Tag
                                                                        color={
                                                                            StatusOfServiceColorMapping[
                                                                                item
                                                                                    ?.serviceDetailStatus
                                                                            ]
                                                                        }
                                                                    >
                                                                        {
                                                                            StatusOfServiceStateMapping[
                                                                                item
                                                                                    ?.serviceDetailStatus
                                                                            ]
                                                                        }
                                                                    </Tag>
                                                                </span>
                                                            )}
                                                        </div>
                                                        {item.serviceDetailStatus ===
                                                        EStatusOfService.DONE ? (
                                                            <div className="flex gap-1 min-w-[120px] items-center justify-end">
                                                                <span>
                                                                    {
                                                                        item.durationInMinutes
                                                                    }
                                                                </span>
                                                                <span>
                                                                    minutes
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <ServicesActionsPage
                                                                service={item}
                                                                size="small"
                                                                mobileMode={
                                                                    false
                                                                }
                                                                oneService={
                                                                    false
                                                                }
                                                                forceReload={
                                                                    true
                                                                }
                                                                carInServiceSelected={
                                                                    record
                                                                }
                                                                onUpdateStatus={
                                                                    onUpdateStatus
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </Descriptions.Item>
                                            );
                                        })}
                                    </Descriptions>
                                </div>
                            ),
                            rowExpandable: record =>
                                record?.services && record?.services?.length > 0
                                    ? true
                                    : false,
                        }}
                    />
                </KeywordContext.Provider>
                {pagination?.totalPages && pagination.totalPages > 1 && (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className="grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]"
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.currentPage}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalRecords}
                            currentPage={pagination?.currentPage}
                            showSizeChanger={false}
                        />
                    </Row>
                )}
            </div>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
        </div>
    );
};
