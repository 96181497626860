export const DEFAULT_SEARCH_PAGE_SIZE = 20;
export const DEFAULT_SEARCH_PAGE_NUM = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_LOAD_MORE = 10;

export const ROLES = ['technician', 'manager'];

export const Role = {
    TECHNICIAN: 'technician',
    MANAGER: 'manager',
};

export const ROLE_MAPPING = {
    technician: 'Technician',
    manager: 'Manager',
};

export const ROLES_OPTIONS = [
    { value: 'technician', label: 'Technician' },
    { value: 'manager', label: 'Manager' },
];

export const AUTH_ERROR = 'auth/invalid-credential';
