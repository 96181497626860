import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { TCar } from '../start-work-order/type';
import { Drawer, FormInstance, Image } from 'antd';
import { LicensePlateForm } from './components/license-plate';
import { AutoTrackButton } from '../../components/button';
import styled from 'styled-components';
import { CameraControl } from './components/camera-control';
import Webcam from 'react-webcam';
import { useScreenSize } from '../../hooks/useScreenSize';
import { FileUpload } from './components/FileUpload';
import { ICClose } from '../../icons';
import { useNotification } from '../../hooks/useNotification';
import { setLoading } from '../../redux/slices/appInfo';
import { useDispatch } from 'react-redux';
import { ApiInstance } from '../../api';
import { ScannerType } from './components/scanner-overlay';
// import { OCRControl } from './components/ocr-control';

const WrapperDocuments = styled.div`
    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
        height: 50px;
        width: 50px;
        .ant-upload.ant-upload-select {
            border-color: #7357ff !important;
            width: 100%;
            height: 100%;
            padding: 5px;
        }
    }
`;

const DrawerBottom = styled(Drawer)`
    .ant-drawer-body {
        padding: 0;
    }
`;

const WrapperSaveButton = styled.div`
    @media (max-width: 768px) {
        .ant-btn {
            border-radius: 0 !important;
        }
    }
`;

// type ProgressInfo = {
//     fileName: string;
//     percentage: number;
// }
type IFile = {
    url: string;
    name: string;
};

export type TAddReceiptsData = {
    carList: TCar[];
    setCarList: React.Dispatch<React.SetStateAction<TCar[]>>;
    carSelected: TCar | undefined;
    setCarSelected: React.Dispatch<React.SetStateAction<TCar | undefined>>;
    addLicensePlateForm: FormInstance<any>;
    selectLicensePlateForm: FormInstance<any>;
};
export const AddReceiptsDataContext = React.createContext<TAddReceiptsData>(
    {} as TAddReceiptsData
);
export const useAddReceipts = () => React.useContext(AddReceiptsDataContext);

export const AddReceiptsPage: React.FC = () => {
    const dispatch = useDispatch();
    const { showError, showSuccess } = useNotification();
    const [carList, setCarList] = React.useState<TCar[]>([]);
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>();
    const [addLicensePlateForm] = useForm();
    const [selectLicensePlateForm] = useForm();

    const webcamRef = React.useRef<Webcam>(null);
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const [openCamera, setOpenCamera] = React.useState(false);
    const [imageList, setImageList] = React.useState<string[]>([]);
    const [currentFileList, setCurrentFileList] = React.useState<File[]>([]);
    const [hasImageCompression, setHasImageCompression] = React.useState(false);

    const onCompletedCapture = (image: string, imageFile: File) => {
        setImageList(pre => [...pre, image]);
        setCurrentFileList(prev => [...prev, imageFile]);
        setOpenCamera(false);
    };

    const progressInfosRef = React.useRef<any>(null);

    const uploadFiles = async () => {
        try {
            if (currentFileList != null && currentFileList.length > 0) {
                const files = Array.from(currentFileList);
                const _progressInfos = files.map(file => ({
                    percentage: 0,
                    fileName: file.name,
                }));
                progressInfosRef.current = _progressInfos;
                const result = (await Promise.all(
                    files.map(image =>
                        ApiInstance.uploadImages(
                            carSelected?.licensePlate || '',
                            image,
                            () => {
                                // TODO: update progress
                                // const index = _progressInfos.findIndex((info) => info.fileName === image.name);
                                // _progressInfos[index].percentage = progress.percentage;
                                // setProgressInfos([..._progressInfos]);
                            }
                        )
                    )
                )) as IFile[];
                if (result) {
                    const message = 'Upload successfully';
                    showSuccess('Success', message);
                    setImageList([]);
                    setCurrentFileList([]);
                }
            }
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            showError('Error', message || 'Please fill in all required fields');
        }
    };

    const onSelectedImage = (image: File) => {
        try {
            if (image instanceof File) {
                setCurrentFileList(pre => [...pre, image]);
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                    if (reader.result) {
                        const newImage = reader.result as string;
                        setImageList(pre => [...pre, newImage]);
                    }
                };
            }
        } catch (error) {
            // TODO: handle error
        }
    };

    const onSubmitForm = React.useCallback(async () => {
        try {
            if (!carSelected) {
                showError('Error', 'Please select a car');
                return;
            }
            if (currentFileList.length === 0) {
                showError('Error', 'Please select at least one image');
                return;
            }
            dispatch(setLoading(true));
            await uploadFiles();
            dispatch(setLoading(false));
        } catch (error) {
            showError('Error', 'Please fill in all required fields');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, carSelected, currentFileList]);

    return (
        <AddReceiptsDataContext.Provider
            value={{
                carList,
                setCarList,
                carSelected,
                setCarSelected,
                addLicensePlateForm,
                selectLicensePlateForm,
            }}
        >
            <div className="flex flex-col w-full bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
                <div className="grid grid-cols-1 gap-[20px] max-md:w-full w-1/2">
                    <LicensePlateForm />
                    <div className="flex flex-col gap-3 w-full">
                        <div>
                            <h4 className="font-bold">Documents</h4>
                            <p className="text-[12px]">
                                Upload the documents that you want to attach to
                                this receipt
                            </p>
                        </div>
                        <WrapperDocuments className="flex w-full gap-[15px]">
                            <FileUpload
                                setOpenCamera={() => {
                                    setHasImageCompression(true);
                                    setOpenCamera(true);
                                }}
                                onSelectedImage={(file: File) => {
                                    setTimeout(() => {
                                        onSelectedImage(file);
                                    }, 1);
                                }}
                            />
                        </WrapperDocuments>
                        {imageList?.length > 0 && (
                            <div className="flex flex-wrap gap-[30px] shadow p-[20px] w-full bg-white border border-gray-300 rounded-[10px]">
                                {imageList.map((image, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex relative rounded-[10px]"
                                        >
                                            <Image
                                                width="70px"
                                                height="70px"
                                                src={image}
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                }}
                                                alt={`image-${index}`}
                                            />
                                            <span
                                                className="flex items-center justify-center p-[5px] absolute top-[-10px] left-[60px] cursor-pointer bg-red-400 h-[25px] w-[25px] rounded-[50%]"
                                                onClick={() => {
                                                    setImageList(
                                                        imageList.filter(
                                                            (_, i) =>
                                                                i !== index
                                                        )
                                                    );
                                                }}
                                            >
                                                <ICClose
                                                    width={20}
                                                    height={20}
                                                    fill="white"
                                                />
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <WrapperSaveButton className="flex justify-start max-md:fixed max-md:bottom-0 max-md:left-0 max-md:w-full pt-0">
                        <span className="max-md:w-full w-1/6">
                            <AutoTrackButton
                                type="primary"
                                htmlType="submit"
                                btnSize="md"
                                classess="max-md:w-full max-md:rounded-none"
                                btnType="primary"
                                onClick={onSubmitForm}
                            >
                                Save
                            </AutoTrackButton>
                        </span>
                    </WrapperSaveButton>
                </div>
                <DrawerBottom
                    title="Take a photo"
                    onClose={() => setOpenCamera(false)}
                    open={openCamera}
                    destroyOnClose
                    zIndex={1000}
                    width="100%"
                    height="100%"
                    placement="bottom"
                >
                    <div className="flex h-full flex-col justify-between w-full gap-3">
                        <div className="flex w-full h-full mt-[0]">
                            <CameraControl
                                webcamRef={webcamRef}
                                isMobile={mobileMode}
                                hasImageCompression={hasImageCompression}
                                isShowScannerOverlay={false}
                                scannerType={ScannerType.PLATE}
                                onCompletedCapture={onCompletedCapture}
                            />
                        </div>
                    </div>
                </DrawerBottom>
            </div>
        </AddReceiptsDataContext.Provider>
    );
};
