import React from 'react';
import { TCar } from '../../start-work-order/type';

type NameOfCarProps = {
    car: TCar | undefined;
};

export const NameOfCar: React.FC<NameOfCarProps> = ({ car }) => {
    return (
        <div className="flex flex-row gap-[5px] flex-wrap">
            <span>{car?.licensePlate}</span>
            <span>|</span>
            <span>{car?.make}</span>
            <span>|</span>
            <span>{car?.model}</span>
        </div>
    );
};
