import { Avatar, Card, Drawer, Modal, Tag } from 'antd';
import React from 'react';
import {
    OrderedListOutlined,
    EditOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import {
    EPriorityCarInService,
    EStatusCarInService,
    EStatusOfService,
    PriorityGradientColorMapping,
    StatusColorMapping,
    StatusOptionsMapping,
    TCarInService,
    TCarInServiceRequest,
    TServiceDetail,
    TServiceRequest,
    TUserService,
} from '../start-work-order/type';
import { AutoTrackTooltip } from '../../components/tooltip';
import classNames from 'classnames';
import { ServicesActionsPage } from './services-actions';
import { ViewServices } from './view-services';
import { useNotification } from '../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/slices/appInfo';
import { saveCarInService } from '../../api/car-in-service';
import { StatusCar } from '../../components/controls/car-status';
import { AutoTrackButton } from '../../components/button';
import { CardServices } from './card-services';
import { CarPhotos } from './car-photos';
import { NameOfCar } from '../components/name-of-car';

const Wrapper = styled.div`
    .ant-card-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        .card-content {
            padding: 16px;
        }
    }
    .card-image {
        .ant-progress .ant-progress-text {
            color: white;
        }
        .ant-progress .ant-progress-inner {
            background: rgba(255, 255, 255, 0.8);
        }
        .actions {
            .action-container {
                border: 1px solid white;
            }
        }
        .view-action {
            color: white;
        }
    }
    .actions {
        .action-container {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid var(--borderColor);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

type TaskItemPageProps = {
    task: TCarInService;
    mobileMode: boolean;
    setCarInServiceSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
    setOpenEditTask: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService | undefined,
        isForceReload: boolean
    ) => void;
    onReload: () => void;
};

export const TaskItemPage: React.FC<TaskItemPageProps> = ({
    task,
    mobileMode,
    setCarInServiceSelected,
    setOpenEditTask,
    onUpdateStatus,
    onReload,
}) => {
    const { showSuccess } = useNotification();
    const dispatch = useDispatch();
    const [isShowServices, setIsShowServices] = React.useState(false);
    const [openCopyTask, setOpenCopyTask] = React.useState(false);
    const [carNeedCopy, setCarNeedCopy] = React.useState<
        TCarInService | undefined
    >(undefined);
    const [currentTask, setCurrentTask] = React.useState<
        TCarInService | undefined
    >(undefined);

    React.useEffect(() => {
        if (task) {
            setCurrentTask(task);
        }
    }, [task]);

    const userNameList = React.useMemo(() => {
        const userServicesTemp: TUserService[] = [];
        if (currentTask?.services?.length === 0) {
            return '';
        }
        currentTask?.services?.forEach(service => {
            if (service?.user) {
                const isUserExist = userServicesTemp.find(
                    user => user.userId === service?.user?.userId
                );
                if (!isUserExist) {
                    userServicesTemp.push(service?.user);
                }
            }
        });
        return userServicesTemp;
    }, [currentTask]);

    const getNickName = (user: TUserService) => {
        const name = user?.username?.split(' ');
        if (name && name.length > 1) {
            return name[0].charAt(0) + name[1].charAt(0);
        }
        return user?.username;
    };

    const allowCopy = React.useMemo(() => {
        return (
            currentTask?.status === EStatusCarInService.PENDING ||
            currentTask?.status === EStatusCarInService.DONE
        );
    }, [currentTask]);

    const renderFooter = React.useMemo(() => {
        const durationInMinutes = currentTask?.services?.reduce(
            (acc, service) => acc + (service?.durationInMinutes || 0),
            0
        ) as number;
        const countDuration =
            durationInMinutes && durationInMinutes < 60
                ? durationInMinutes
                : durationInMinutes
                  ? (durationInMinutes / 60).toFixed(2)
                  : 0;
        const renderText = durationInMinutes < 60 ? 'minutes' : 'hours';
        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted font-normal text-[12px]">
                        Total Duration:
                    </span>
                    <span className="font-bold">
                        {countDuration} {renderText}
                    </span>
                </div>
            </div>
        );
    }, [currentTask]);

    const onUpdateCarInService = (taskTemp: TCarInService) => {
        setCurrentTask(taskTemp);
    };

    const onCopyCarInService = async () => {
        try {
            if (carNeedCopy) {
                dispatch(setLoading(true));
                const completedBy = new Date().toISOString();
                const newServices = carNeedCopy?.services?.map(item => ({
                    serviceId: item.serviceId,
                    userId: item.user ? item.user.userId : undefined,
                    saveCarInService: EStatusCarInService.PENDING,
                    completedBy: completedBy,
                }));
                const payload: TCarInServiceRequest = {
                    carId: carNeedCopy?.carId,
                    services: newServices as TServiceRequest[],
                    status: EStatusCarInService.PENDING,
                    priority: EPriorityCarInService.MEDIUM,
                };
                const result = await saveCarInService(payload);
                if (result.status === 201) {
                    showSuccess(
                        'Save service success',
                        'Service has been created'
                    );
                    setOpenCopyTask(false);
                    onReload();
                }
            }
        } catch (error) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    };

    const openEditCurrentTask = () => {
        setCarInServiceSelected(currentTask);
        setOpenEditTask(true);
    };

    return (
        <Wrapper className="flex flex-col relative">
            <Card
                className={classNames([
                    'relative flex flex-col cursor-pointer rounded-[12px] max-md:hover:ring-0 hover:ring-2 hover:ring-[var(--primaryColor)] shadow-card-shadow',
                ])}
                style={{
                    background: currentTask?.priority
                        ? PriorityGradientColorMapping[currentTask?.priority]
                        : 'white',
                }}
            >
                <CarPhotos currentTask={currentTask} />
                <div className="flex flex-col gap-[15px] relative card-content">
                    <div className="flex flex-col gap-1">
                        <div className="flex mb-0 justify-between items-center text-center gap-[10px]">
                            <div className="mb-0 text-left text-body font-bold line-clamp-1">
                                <NameOfCar car={currentTask?.car} />
                            </div>
                            <div className="flex actions gap-2">
                                {allowCopy && (
                                    <AutoTrackTooltip
                                        content="Copy"
                                        placement="top"
                                        childNode={
                                            <div
                                                className="action-container cursor-pointer shadow-big-shadow"
                                                onClick={() => {
                                                    setCarNeedCopy(currentTask);
                                                    setOpenCopyTask(true);
                                                }}
                                            >
                                                <CopyOutlined color="black" />
                                            </div>
                                        }
                                    />
                                )}
                                {currentTask?.status !==
                                    EStatusCarInService.DONE && (
                                    <AutoTrackTooltip
                                        content="Edit"
                                        placement="top"
                                        childNode={
                                            <div
                                                className="action-container cursor-pointer shadow-big-shadow"
                                                onClick={() =>
                                                    openEditCurrentTask()
                                                }
                                            >
                                                <EditOutlined color="black" />
                                            </div>
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames([
                            'grid items-center gap-3 max-md:min-h-[auto] min-h-[40px]',
                            {
                                'grid-cols-1':
                                    currentTask?.services?.length === 1,
                            },
                            {
                                'grid-cols-[10px_1fr]':
                                    currentTask?.services &&
                                    currentTask.services?.length > 1,
                            },
                        ])}
                    >
                        {currentTask?.services &&
                            currentTask?.services?.length > 1 && (
                                <div className="flex flex-row items-center gap-2">
                                    <OrderedListOutlined />
                                </div>
                            )}
                        <div
                            className={classNames([
                                'w-full justify-start items-center gap-3',
                                {
                                    'grid grid-cols-[1fr_150px]':
                                        currentTask?.services?.length === 1,
                                    'flex flex-row flex-wrap':
                                        currentTask?.services &&
                                        currentTask?.services?.length > 1,
                                },
                            ])}
                        >
                            {currentTask?.services?.length === 1 ? (
                                <div className="flex max-md:w-full max-md:items-center justify-between text-muted font-13">
                                    <span className="w-full line-clamp-1">
                                        {
                                            currentTask?.services[0]?.service
                                                ?.name
                                        }
                                    </span>
                                </div>
                            ) : (
                                <div
                                    className="flex gap-3 font-13"
                                    onClick={() => setIsShowServices(true)}
                                >
                                    <span className="text-muted">
                                        {currentTask?.services?.length} services
                                    </span>
                                    <span className="text-info-bold underline cursor-pointer view-action">
                                        View
                                    </span>
                                </div>
                            )}
                            {currentTask?.services?.length === 1 && (
                                <ServicesActionsPage
                                    service={currentTask?.services[0]}
                                    carInServiceSelected={currentTask}
                                    oneService={true}
                                    mobileMode={mobileMode}
                                    onUpdateStatus={onUpdateStatus}
                                />
                            )}
                        </div>
                    </div>
                    <StatusCar task={currentTask} />
                    <div className="flex items-center justify-between gap-[5px] w-full min-h-[32px]">
                        <Avatar.Group>
                            {userNameList &&
                                userNameList?.map((user, index) => (
                                    <AutoTrackTooltip
                                        key={index}
                                        content={user?.username}
                                        placement="top"
                                        childNode={
                                            <Avatar
                                                key={index}
                                                style={{
                                                    backgroundColor: '#f56a00',
                                                }}
                                            >
                                                {getNickName(user)}
                                            </Avatar>
                                        }
                                    />
                                ))}
                        </Avatar.Group>
                        <div>
                            {currentTask?.status && (
                                <Tag
                                    color={
                                        StatusColorMapping[currentTask?.status]
                                    }
                                >
                                    {StatusOptionsMapping[currentTask?.status]}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {!mobileMode && (
                <Modal
                    title={<NameOfCar car={currentTask?.car} />}
                    open={isShowServices}
                    onCancel={() => setIsShowServices(false)}
                    centered
                    destroyOnClose
                    afterClose={() => onReload()}
                    footer={null}
                    width={800}
                >
                    <ViewServices task={currentTask} />
                </Modal>
            )}
            {mobileMode && (
                <Drawer
                    title={
                        <div className="flex items-center justify-between gap-3">
                            <div className="flex flex-col gap-2">
                                <h3>
                                    <NameOfCar car={currentTask?.car} />
                                </h3>
                                <div>{renderFooter}</div>
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={() => setIsShowServices(false)}
                            >
                                <AutoTrackButton
                                    btnSize="sm"
                                    btnType="sub_danger"
                                >
                                    Close
                                </AutoTrackButton>
                            </div>
                        </div>
                    }
                    onClose={() => setIsShowServices(false)}
                    open={isShowServices}
                    destroyOnClose
                    closable={false}
                    zIndex={1000}
                    width={'100%'}
                    height={'90%'}
                    placement="bottom"
                    afterOpenChange={visible => {
                        if (!visible) {
                            onReload();
                        }
                    }}
                    classNames={{
                        body: 'body-card-services-drawer',
                    }}
                >
                    <CardServices
                        task={currentTask}
                        onUpdateCarInService={onUpdateCarInService}
                    />
                </Drawer>
            )}
            <Modal
                title="Copy Task"
                open={openCopyTask}
                onCancel={() => setOpenCopyTask(false)}
                onOk={onCopyCarInService}
                centered
                destroyOnClose
                afterClose={() => setCarNeedCopy(undefined)}
            >
                <div className="flex flex-col gap-3">
                    Do you want to copy task for {carNeedCopy?.car?.make}{' '}
                    {carNeedCopy?.car?.model}?
                </div>
            </Modal>
        </Wrapper>
    );
};
