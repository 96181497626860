import React from 'react';
import { useNotification } from '../../../../hooks/useNotification';
import { AutoTrackButton } from '../../../../components/button';
import styled from 'styled-components';
import { ICCamera, ICImage } from '../../../../icons';

const WrapperCamera = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

type FileUploadProps = {
    setOpenCamera: React.Dispatch<React.SetStateAction<boolean>>;
    onSelectedImage: (image: File) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    setOpenCamera,
    onSelectedImage,
}) => {
    const { showError } = useNotification();
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const uploadButton = (
        <div className="flex flex-col p-[10px] items-center justify-center w-full gap-2">
            <ICImage fill="#7357FF" width={22} height={22} />
        </div>
    );

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files || [];
        if (selectedFiles.length === 0) {
            return;
        }
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (file.size > 5 * 1024 * 1024) {
                showError('Error', 'File size should be less than 5MB');
                continue;
            }
            onSelectedImage(file);
        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="flex flex-row max-md:items-center max-md:justify-center max-md:gap-[15px] gap-[40px] h-full w-full border-none max-md:border-solid max-md:border border-gray-300 p-3 pt-[15px] rounded-lg">
            <div className="flex max-md:w-full max-md:items-center max-md:justify-center">
                <div className="flex flex-col items-center justify-center gap-[8px] max-md:w-full">
                    <div
                        onClick={triggerFileInput}
                        className="flex h-[42px] w-[50px] border border-[#7357ff] rounded-[10px] cursor-pointer"
                    >
                        {uploadButton}
                    </div>
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        accept="image/*"
                    />
                    <div>
                        <span className="text-xs text-center">
                            Select Image
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex max-md:w-full max-md:items-center max-md:justify-center">
                <WrapperCamera>
                    <AutoTrackButton
                        btnSize="sm"
                        classess="max-md:w-full"
                        btnType="outline"
                        style={{
                            height: '42px',
                            width: '50px',
                            borderRadius: '10px',
                            borderColor: '#3FB483',
                            padding: '0',
                        }}
                        onClick={() => setOpenCamera(true)}
                    >
                        <ICCamera fill="#3FB483" width={30} height={30} />
                    </AutoTrackButton>
                    <div>
                        <span className="text-xs text-center">Camera</span>
                    </div>
                </WrapperCamera>
            </div>
        </div>
    );
};
