import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form, notification as Notification } from 'antd';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackForm } from '../../../components/form/form';
import {
    AutoTrackInput,
    AutoTrackInputPassword,
} from '../../../components/form/input';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ICCheckFill, ICClose, ICWarning } from '../../../icons';
import { auth } from '../../../firebase/firebaseConfig';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword } from 'firebase/auth';
import { ApiInstance } from '../../../api';
import { setUserInfo } from '../../../redux/actions';
import { TUserInfo } from '../../../redux/slices/userInfo';
import { changePassword, TChangePassword } from '../../../api/setting/users';
import { validatePasswordStrength } from '../../../utils/passwordValidator';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Wrapper = styled.div`
    background-color: #7357ff;
    background-image: url('/images/bg-pattern.png');
    background-size: cover;
    background-repeat: no-repeat;
`;

type FieldType = {
    email: string;
    password: string;
    confirmPassword: string;
};

export const ChangePasswordPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changePasswordForm] = useForm<FieldType>();
    const validatePassword = (_: RuleObject, value: StoreValue) => {
        if (!value || changePasswordForm.getFieldValue('password') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords do not match!'));
    };

    // get value from url
    const { email } = useParams();

    const [api, contextHolder] = Notification.useNotification();
    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => {
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const onFinished = React.useCallback(
        async (values: FieldType) => {
            try {
                dispatch(setLoading(true));
                const { email, password } = values || {};
                const payload: TChangePassword = {
                    email,
                    newPassword: password,
                };
                await changePassword(payload);
                openNotification(
                    'success',
                    'Change Password success',
                    'Password has been changed'
                );
                setTimeout(() => {
                    onLoginPage(email, password);
                }, 500);
            } catch (error) {
                dispatch(setLoading(false));
                openNotification(
                    'error',
                    'Register failed',
                    'Please check your email and password'
                );
            }
        },
        [dispatch, openNotification]
    );

    const onLoginPage = React.useCallback(
        async (email: string, password: string) => {
            try {
                const userCredential = await firebaseSignInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                if (userCredential.user) {
                    localStorage.clear();
                    const newUserInfo: TUserInfo = {
                        Id: userCredential.user.uid,
                        PhoneNumber: userCredential.user.phoneNumber,
                        UserIdentifier: userCredential.user.uid,
                        Email: userCredential.user.email,
                    };
                    const tokenResult =
                        await userCredential.user.getIdTokenResult();
                    ApiInstance.setToken(tokenResult.token);
                    newUserInfo.token = tokenResult.token;
                    dispatch(setUserInfo(newUserInfo));
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify(newUserInfo)
                    );
                    localStorage.setItem('token', tokenResult.token);
                    localStorage.setItem(
                        'refreshToken',
                        userCredential.user.refreshToken || ''
                    );
                    setTimeout(() => {
                        navigate('/work-order');
                    }, 5);
                }
            } catch (error: any) {
                openNotification(
                    'error',
                    'Login failed',
                    'Please check your email and password'
                );
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, navigate, openNotification]
    );

    React.useEffect(() => {
        if (email) {
            changePasswordForm.setFieldsValue({
                email,
            });
        }
    }, [email]);

    return (
        <Wrapper className="flex w-screen h-screen items-center justify-center bg-surface-low">
            {contextHolder}
            <div className="shadow-e-03 max-md:max-w-[478px] max-md:w-auto w-[478px] p-[32px] flex flex-col gap-[24px] rounded-[12px] overflow-hidden box-border bg-white">
                <div className="flex justify-center">
                    <h1 className="text-heading-5 text-high-em font-semibold">
                        Change Password
                    </h1>
                </div>
                <AutoTrackForm
                    form={changePasswordForm}
                    name="changePasswordForm"
                    layout="vertical"
                    onFinish={onFinished}
                    childNode={
                        <>
                            <div className="form flex flex-col w-full gap-[16px]">
                                <div>
                                    <Form.Item<FieldType>
                                        label="Email"
                                        name="email"
                                        required
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            disabled
                                            maxLength={50}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="New Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your new password!',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    const { isValid } =
                                                        validatePasswordStrength(
                                                            value
                                                        );
                                                    if (isValid) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not strong enough'
                                                        )
                                                    );
                                                },
                                            },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Confirm New Password"
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please confirm your password!',
                                            },
                                            { validator: validatePassword },
                                            {
                                                validator: (_, value) => {
                                                    const { isValid } =
                                                        validatePasswordStrength(
                                                            value
                                                        );
                                                    if (isValid) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not strong enough'
                                                        )
                                                    );
                                                },
                                            },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Confirm New Password"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-[24px] pt-[16px]">
                                <div>
                                    <AutoTrackButton htmlType="submit">
                                        Save
                                    </AutoTrackButton>
                                </div>
                                <div className="flex gap-[16px] items-center justify-center text-center text-high-em text-body">
                                    <Link
                                        to="/login"
                                        className="underline text-info-500 font-medium"
                                    >
                                        Back to login
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        </Wrapper>
    );
};
