import React from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/slices/appInfo';
import {
    EServiceRecordStatus,
    TServiceRecordRequest,
} from '../api/car-in-service/type';
import {
    EStatusCarInService,
    EStatusOfService,
    EUpdateType,
    TCarInService,
    TCarInServiceRequest,
    TServiceDetail,
    TServiceRequest,
} from '../pages/start-work-order/type';
import {
    addServiceRecord,
    getCarInServiceById,
    updateCarInService,
} from '../api/car-in-service';

export const useUpdateService = () => {
    const dispatch = useDispatch();
    const [serviceDetails, setServiceDetails] = React.useState<
        TCarInService | undefined
    >(undefined);

    const onUpdateStatus = React.useCallback(
        async (
            status: EStatusOfService,
            serviceSelection: TServiceDetail,
            taskSelected: TCarInService
        ) => {
            try {
                if (!taskSelected) return;
                dispatch(setLoading(true));
                const nowDateUTC = new Date().toISOString();
                const newServices: TServiceRequest[] =
                    taskSelected.services?.map(item => {
                        if (
                            item?.serviceDetailId ===
                            serviceSelection.serviceDetailId
                        ) {
                            if (status === EStatusOfService.PAUSE) {
                                return {
                                    serviceId: item?.service?.serviceId,
                                    userId: item?.user?.userId,
                                    serviceDetailId: item?.serviceDetailId,
                                    serviceDetailStatus: status,
                                    updateType: EUpdateType.UPDATE,
                                    startTime: item?.startTime,
                                    stopTime: nowDateUTC,
                                } as TServiceRequest;
                            }
                            if (status === EStatusOfService.UNPAUSE) {
                                return {
                                    serviceId: item?.service?.serviceId,
                                    userId: item?.user?.userId,
                                    serviceDetailId: item?.serviceDetailId,
                                    serviceDetailStatus: EStatusOfService.START,
                                    updateType: EUpdateType.UPDATE,
                                    startTime: nowDateUTC,
                                } as TServiceRequest;
                            }
                            if (status === EStatusOfService.DONE) {
                                return {
                                    serviceId: item?.service?.serviceId,
                                    userId: item?.user?.userId,
                                    serviceDetailId: item?.serviceDetailId,
                                    serviceDetailStatus: status,
                                    updateType: EUpdateType.UPDATE,
                                    startTime: item?.startTime,
                                    stopTime: nowDateUTC,
                                } as TServiceRequest;
                            }
                            return {
                                serviceId: item?.service?.serviceId,
                                userId: item?.user?.userId,
                                serviceDetailId: item?.serviceDetailId,
                                serviceDetailStatus: status,
                                updateType: EUpdateType.UPDATE,
                                startTime: nowDateUTC,
                            } as TServiceRequest;
                        }
                        return {
                            serviceId: item?.service?.serviceId,
                            userId: item?.user?.userId,
                            serviceDetailId: item?.serviceDetailId,
                            serviceDetailStatus: item?.serviceDetailStatus,
                            updateType: EUpdateType.NOT_MODIFY,
                        } as TServiceRequest;
                    }) as TServiceRequest[];
                // check status of car
                let statusOfCar = EStatusCarInService.INPROGRESS;
                const doneServices = newServices?.filter(
                    service =>
                        service?.serviceDetailStatus === EStatusOfService.DONE
                );
                if (doneServices?.length === newServices?.length) {
                    statusOfCar = EStatusCarInService.DONE;
                }
                const pauseServices = newServices?.filter(
                    service =>
                        service?.serviceDetailStatus === EStatusOfService.PAUSE
                );
                if (pauseServices?.length === newServices?.length) {
                    statusOfCar = EStatusCarInService.ON_HOLD;
                }
                const unPauseServices = newServices?.filter(
                    service =>
                        service?.serviceDetailStatus ===
                        EStatusOfService.UNPAUSE
                );
                if (unPauseServices?.length === newServices?.length) {
                    statusOfCar = EStatusCarInService.INPROGRESS;
                }
                // done status
                const doneStatus = newServices?.filter(
                    service =>
                        service?.serviceDetailStatus === EStatusOfService.DONE
                );
                if (doneStatus?.length === newServices?.length) {
                    statusOfCar = EStatusCarInService.DONE;
                }
                const completedBy = taskSelected?.completedBy
                    ? new Date(taskSelected?.completedBy).toISOString()
                    : nowDateUTC;
                const payload: TCarInServiceRequest = {
                    carId: taskSelected?.carId,
                    carInServiceId: taskSelected?.carInServiceId,
                    completedBy: completedBy,
                    services: newServices,
                    priority: taskSelected?.priority,
                    status: statusOfCar,
                };
                const result = await updateCarInService(
                    taskSelected?.carInServiceId ?? 0,
                    payload
                );
                if (result.status === 200) {
                    // add to write logs
                    let statusOfService: EServiceRecordStatus =
                        EServiceRecordStatus.START;
                    if (status === EStatusOfService.START) {
                        statusOfService = EServiceRecordStatus.START;
                    }
                    if (status === EStatusOfService.PAUSE) {
                        statusOfService = EServiceRecordStatus.PAUSE;
                    }
                    if (status === EStatusOfService.UNPAUSE) {
                        statusOfService = EServiceRecordStatus.UNPAUSING;
                    }
                    if (status === EStatusOfService.DONE) {
                        statusOfService = EServiceRecordStatus.DONE;
                    }

                    const payload: TServiceRecordRequest = {
                        carInServiceId: taskSelected?.carInServiceId ?? 0,
                        serviceDetailId: serviceSelection.serviceDetailId ?? 0,
                        carId: taskSelected?.carId ?? 0,
                        status: statusOfService,
                    };
                    await addServiceRecord(payload);
                    const serviceDetailsResponse = await getCarInServiceById(
                        taskSelected?.carInServiceId ?? 0
                    );
                    if (serviceDetailsResponse.status === 200) {
                        if (serviceDetailsResponse.data?.length) {
                            setServiceDetails(serviceDetailsResponse.data[0]);
                        }
                    }
                }
            } catch (error) {
                dispatch(setLoading(false));
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch]
    );

    return {
        onUpdateStatus,
        serviceDetails,
    };
};
