import { IconProps } from './type';

export const ICReport: React.FC<IconProps> = ({
    fill = 'var(--icon-high-em)',
    width = 18,
    height = 18,
    className = 'svgFillColor',
}) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill={fill}
            height={height}
            width={width}
            className={className}
        >
            <path d="M20 8l-6-6H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8zM9 19H7v-9h2v9zm4 0h-2v-6h2v6zm4 0h-2v-3h2v3zM14 9h-1V4l5 5h-4z" />
        </svg>
    );
};
