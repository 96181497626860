import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps, Modal, Space } from 'antd';
import React from 'react';
import {
    CheckOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { TTheme, TThemeConfig, getThemes } from '../../../api/theme';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackTooltip } from '../../../components/tooltip';
import { ICLogoutUser, ICSetting } from '../../../icons';
import { removeUserInfo } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useSignOut } from 'react-firebase-hooks/auth';
import { setLoading } from '../../../redux/slices/appInfo';
import { useNotification } from '../../../hooks/useNotification';
import { auth } from '../../../firebase/firebaseConfig';
import { NotificationControl } from './notification-control';
import { ChangePasswordForm } from '../change-password-form';

type HeaderAdminProps = {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    data: TTheme[];
    onSelectedTheme: (theme: TThemeConfig) => void;
};

export const HeaderAdmin: React.FC<HeaderAdminProps> = ({
    collapsed,
    setCollapsed,
    data = [],
    onSelectedTheme,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotification();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [currentTheme, setCurrentTheme] = React.useState<string>('purple');
    const [open, setOpen] = React.useState(false);
    const [signOut] = useSignOut(auth);
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const [openChangPasswordDialog, setOpenChangePasswordDialog] =
        React.useState(false);

    const headerMenuOptions = [
        {
            key: '1',
            label: 'Create a work order',
            icon: <PlusOutlined />,
            link: '/start-work-order',
        },
    ];

    const userName = React.useMemo(() => {
        if (!userInfo) {
            return '';
        }
        if (Object.keys(userInfo).length === 0) {
            return '';
        }
        if (!userInfo?.FirstName && !userInfo?.LastName) {
            return userInfo?.Email;
        }
        return `${userInfo?.FirstName} ${userInfo?.LastName}`;
    }, [userInfo]);

    const emailUserLogin = React.useMemo(() => {
        if (!userInfo) {
            return '';
        }
        return userInfo?.Email || '';
    }, [userInfo]);

    const avatarUser = React.useMemo(() => {
        return '/images/avatar_holder.jpg';
    }, []);

    const userList = [
        {
            label: (
                <div className="flex flex-row gap-[16px] items-center justify-start font-bold p-[16px]">
                    <img
                        alt=""
                        src={avatarUser}
                        className="w-[35px] h-[35px] rounded-full"
                    />
                    <span className="text-high-em">{userName}</span>
                </div>
            ),
            key: 'Welcome',
        },
        {
            label: (
                <div className="flex flex-row gap-[16px] items-center justify-start p-[16px] text-standard-medium text-med-em">
                    <ICSetting />
                    <span
                        className="text-med-em"
                        onClick={() => setOpenChangePasswordDialog(true)}
                    >
                        Change password
                    </span>
                </div>
            ),
            key: 'change_password',
        },
        {
            label: (
                <div className="flex flex-row gap-[16px] items-center justify-start p-[16px] text-standard-medium text-med-em">
                    <ICLogoutUser />
                    <span
                        className="text-[#F03D3D]"
                        onClick={() => setOpen(true)}
                    >
                        Sign out
                    </span>
                </div>
            ),
            key: 'sign_out',
        },
    ];

    const items = React.useMemo<MenuProps['items']>(() => {
        return data.map(item => ({
            key: item.value,
            label: (
                <div
                    className={classNames([
                        'flex gap-3 items-center justify-between min-w-[140px]',
                        {
                            'text-primary': currentTheme === item.value,
                        },
                    ])}
                >
                    <span>{item.name}</span>
                    {currentTheme === item.value && (
                        <span>
                            <CheckOutlined />
                        </span>
                    )}
                </div>
            ),
        }));
    }, [currentTheme, data]);

    const onChangeTheme = (e: any) => {
        setCurrentTheme(e.key);
        fetchThemes(e.key);
    };

    const fetchThemes = React.useCallback(async (currentTheme: string) => {
        const result = await getThemes(currentTheme);
        if (result?.data?.config) {
            const theme = result.data.config as TThemeConfig;
            onSelectedTheme(theme);
        }
    }, []);

    React.useEffect(() => {
        fetchThemes(currentTheme);
    }, []);

    const onClickUser: MenuProps['onClick'] = ({ key }) => {
        if (key === 'sign_out') {
            setOpen(true);
        }
        if (key === 'change_password') {
            setOpenChangePasswordDialog(true);
        }
    };
    const onLogout = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const success = await signOut();
            if (success) {
                showSuccess('Sign out success', 'You have been signed out');
            }
            setOpen(false);
            dispatch(removeUserInfo());
            navigate('/login');
        } catch (error) {
            dispatch(setLoading(false));
            showError('Sign out failed', 'Please try again');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, signOut, showSuccess, showError, navigate]);

    return (
        <div className="flex h-full w-full bg-white">
            <div
                className={classNames([
                    'items-center h-[60px] w-full',
                    { 'grid grid-cols-[200px_auto]': !mobileMode },
                    { 'flex pl-[10px]': mobileMode },
                ])}
            >
                {!mobileMode && (
                    <div
                        className={classNames([
                            'flex w-full items-center justify-center cursor-pointer',
                        ])}
                        onClick={() => navigate('/')}
                    >
                        <img
                            src="/images/logo.png"
                            alt="logo"
                            className="flex w-full h-[60px] object-contain border-none rounded-[0px] shadow-none"
                        />
                    </div>
                )}
                <div className="flex w-full justify-between items-center px-[15px]">
                    <div className="flex">
                        <div className="flex items-center justify-center gap-3">
                            <div
                                className="flex cursor-pointer pr-3"
                                onClick={() => setCollapsed(!collapsed)}
                            >
                                {collapsed ? (
                                    <MenuUnfoldOutlined
                                        style={{ fontSize: '20px' }}
                                    />
                                ) : (
                                    <MenuFoldOutlined
                                        style={{ fontSize: '20px' }}
                                    />
                                )}
                            </div>
                            <div>
                                {headerMenuOptions.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item.link}
                                        className="flex items-center justify-center cursor-pointer hover:text-primary gap-2"
                                    >
                                        <div className="flex items-center justify-center">
                                            {item.icon}
                                        </div>
                                        <span>{item.label}</span>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-[16px]">
                        <NotificationControl />
                        {!mobileMode && (
                            <div className="flex items-center justify-center pt-[8px] max-h-[60px]">
                                <div className="flex items-center justify-center cursor-pointer hover:text-primary">
                                    <Dropdown
                                        menu={{ items, onClick: onChangeTheme }}
                                        trigger={['click']}
                                        placement="bottomRight"
                                        arrow={{ pointAtCenter: true }}
                                    >
                                        <a onClick={e => e.preventDefault()}>
                                            <Space>
                                                <ICSetting />
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>
                        )}
                        {userName && (
                            <div className="flex items-center font-bold">
                                <Dropdown
                                    menu={{
                                        items: userList,
                                        onClick: onClickUser,
                                    }}
                                    overlayClassName="user-dropdown"
                                    getPopupContainer={triggerNode =>
                                        triggerNode.parentNode as HTMLElement
                                    }
                                    overlayStyle={{
                                        minWidth: '272px',
                                        borderRadius: '12px',
                                        color: '#110C22',
                                        fontWeight: 500,
                                    }}
                                    placement="bottomRight"
                                    className="cursor-pointer"
                                    trigger={['click']}
                                    arrow
                                >
                                    <div onClick={e => e.preventDefault()}>
                                        <div className="flex gap-[19px] items-center justify-center">
                                            {!mobileMode && (
                                                <div>
                                                    <AutoTrackTooltip
                                                        placement="left"
                                                        content={userName || ''}
                                                        childNode={
                                                            <span className="text-body-medium text-high-em w-full">
                                                                {userName}
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div className="w-[35px] h-[35px] min-w-[35px] rounded-[32px] box-border shadow-l-small">
                                                <img
                                                    alt="user"
                                                    src={avatarUser}
                                                    className="border-[3px] border-solid border-white w-full h-full rounded-full box-border"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                title="Sign out"
                open={open}
                centered={true}
                onCancel={() => setOpen(false)}
                okText="OK"
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                        <AutoTrackButton
                            btnSize="sm"
                            btnType="sub"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton btnSize="sm" onClick={onLogout}>
                            OK
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to sign out?</p>
            </Modal>
            <Modal
                title="Change password"
                open={openChangPasswordDialog}
                centered={true}
                onCancel={() => setOpenChangePasswordDialog(false)}
                okText="OK"
                footer={null}
            >
                <ChangePasswordForm
                    email={emailUserLogin}
                    setOpenChangePasswordDialog={setOpenChangePasswordDialog}
                />
            </Modal>
        </div>
    );
};
