import { ApiInstance } from '../..';
import { SearchQueryParams } from '../../../interface/paging';
import { TUserService } from '../../../pages/start-work-order/type';
import { convert2QueryString } from '../../../utils/convert2QueryString';

export type TChangePassword = {
    email: string;
    newPassword: string;
};

export const getUsers = () => {
    return ApiInstance.getInstance().get('/api/users');
};

export const getUsersPaging = (params: SearchQueryParams | undefined) => {
    const requestURL = '/api/users/search?' + convert2QueryString(params);
    return ApiInstance.getInstance().get(requestURL);
};

export const createUser = (data: TUserService) => {
    const payload = { ...data, email: data.email?.toLowerCase() };
    return ApiInstance.getInstance().post('/api/users/add', payload);
};

export const updateUser = (
    userId: string | undefined | number,
    data: TUserService
) => {
    const baseUrl = `/api/users/${userId}`;
    const payload = { ...data, email: data.email?.toLowerCase() };
    return ApiInstance.getInstance().put(baseUrl, payload);
};

export const deleteUser = (userId: string | undefined | number) => {
    const baseUrl = `/api/users/${userId}`;
    return ApiInstance.getInstance().delete(baseUrl);
};

export const getUserInfo = (email: string) => {
    return ApiInstance.getInstance().get(`/api/users/email/${email}`);
};

export const changePassword = (data: TChangePassword) => {
    return ApiInstance.getInstance().put('/api/users/changePassword', data);
};

export const addRole = (
    userId: string | undefined | number,
    role: string[]
) => {
    return ApiInstance.getInstance().post(
        `/api/users/roles/add/${userId}`,
        role
    );
};

export const updateRole = (
    userId: string | undefined | number,
    role: string[]
) => {
    return ApiInstance.getInstance().put(
        `/api/users/roles/update/${userId}`,
        role
    );
};

export const removeRole = (
    userId: string | undefined | number,
    role: string[]
) => {
    return ApiInstance.getInstance().delete(
        `/api/users/roles/delete/${userId}`,
        { data: role }
    );
};

export const addFirebaseToken = (
    userId: string | undefined | number,
    payload: string[]
) => {
    const baseUrl = '/api/users/fbToken/add?id=' + userId;
    return ApiInstance.getInstance().put(baseUrl, payload);
};
