import { ApiInstance } from '..';

export const getImageByLicensePlate = (licensePlate: string) => {
    return ApiInstance.getInstance().get(`/api/images/url/${licensePlate}`);
};

export const getImageCarByCarId = (carId: number | string) => {
    return ApiInstance.getInstance().get(`/api/carImages/url/${carId}`);
};

export const downloadImage = (imageId: string) => {
    return ApiInstance.getInstance().get(`/api/images/download/${imageId}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const deleteCarImage = (imageId: string) => {
    return ApiInstance.getInstance().delete(`/api/carImages/delete/${imageId}`);
};

export const deleteImage = (imageId: string) => {
    return ApiInstance.getInstance().delete(`/api/images/delete/${imageId}`);
};
