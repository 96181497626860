export type TStepItem = {
    title: string;
    component: React.ReactElement;
};

export type TCarImage = {
    carId: number;
    carImageId: number;
    imageUrl: string;
};

export type TCar = {
    carId?: number | string;
    make: string;
    model: string;
    year: number | string;
    color: string;
    vin: string;
    licensePlate: string;
    createdAt?: string;
    notes?: string;
    bouncie?: string;
    ntta?: string;
    owner?: string;
    purchaseDate?: string;
    cost?: number;
    insurance?: string;
    mileage?: number;

    registration?: string;
    keys?: string;

    carImageId?: number;
    carImage?: TCarImage;
};

export type TCarImport = {
    Make: string;
    Model: string;
    Year: string | number;
    Color: string;
    VIN: string;
    LicensePlate: string;
    Notes: string;
    Bouncie: string;
    NTTA: string;
    Owner: string;
    PurchaseDate: string;
    Cost: number;
    Insurance: string;
    Mileage: any;

    Registration: string;
    Keys: string;

    isValid?: boolean;
    errorMessage?: string;
};

export type TService = {
    serviceId: number;
    name: string;
    price: number;
    description?: string;
    createdAt?: string;
};

export type TUserService = {
    userId?: number;
    username: string;
    email: string;
    passwordHash?: string;
    createdAt?: string;
    roles?: string[];
    phoneNumber: string[];
};

export type TRepairService = TService & {
    users: TUserService[];
};

export enum EStatusCarInService {
    ON_HOLD = 'ON_HOLD',
    PENDING = 'PENDING',
    DONE = 'DONE',
    INPROGRESS = 'INPROGRESS',
}

export enum EStatusOfService {
    START = 'START',
    PAUSE = 'PAUSE',
    UNPAUSE = 'UNPAUSE',
    DONE = 'DONE',
}

export const StatusOfServiceOptions = [
    { label: 'Start', value: EStatusOfService.START },
    { label: 'Pause', value: EStatusOfService.PAUSE },
    { label: 'Unpause', value: EStatusOfService.UNPAUSE },
    { label: 'Done', value: EStatusOfService.DONE },
];

export const StatusOfServiceMapping = {
    [EStatusOfService.START]: 'In Progress',
    [EStatusOfService.PAUSE]: 'Pause',
    [EStatusOfService.UNPAUSE]: 'UnPause',
    [EStatusOfService.DONE]: 'Done',
};

export const StatusOfServiceStateMapping = {
    [EStatusOfService.START]: 'In Progress',
    [EStatusOfService.PAUSE]: 'Pause',
    [EStatusOfService.UNPAUSE]: 'In Progress',
    [EStatusOfService.DONE]: 'Done',
};

export const StatusOfServiceColorMapping = {
    [EStatusOfService.START]: '#1890ff',
    [EStatusOfService.PAUSE]: '#f50',
    [EStatusOfService.UNPAUSE]: '#1890ff',
    [EStatusOfService.DONE]: '#87d068',
};

export const StatusOfServiceGradientColorMapping = {
    [EStatusOfService.START]: 'linear-gradient(180deg, #91d5ff, #F4FAFF)',
    [EStatusOfService.PAUSE]: 'linear-gradient(180deg, #ffbb96, #FFF8F4)',
    [EStatusOfService.UNPAUSE]: 'linear-gradient(180deg, #FFDFB4, #FFF8F0)',
    [EStatusOfService.DONE]: 'linear-gradient(180deg, #b7eb8f, #F4FAF0)',
};

export const StatusOptions = [
    { label: 'Pending', value: EStatusCarInService.PENDING },
    { label: 'In Progress', value: EStatusCarInService.INPROGRESS },
    { label: 'On Hold', value: EStatusCarInService.ON_HOLD },
    { label: 'Done', value: EStatusCarInService.DONE },
];

export const StatusColorMapping = {
    [EStatusCarInService.ON_HOLD]: 'red',
    [EStatusCarInService.PENDING]: 'orange',
    [EStatusCarInService.DONE]: 'green',
    [EStatusCarInService.INPROGRESS]: 'blue',
};

export const StatusOptionsMapping = {
    [EStatusCarInService.ON_HOLD]: 'On Hold',
    [EStatusCarInService.PENDING]: 'Pending',
    [EStatusCarInService.DONE]: 'Done',
    [EStatusCarInService.INPROGRESS]: 'In Progress',
};

export enum EPriorityCarInService {
    URGENT = 'URGENT',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export const EPriorityCarInServiceOptions = [
    { label: 'Urgent', value: EPriorityCarInService.URGENT },
    { label: 'High', value: EPriorityCarInService.HIGH },
    { label: 'Medium', value: EPriorityCarInService.MEDIUM },
    { label: 'Low', value: EPriorityCarInService.LOW },
];

export const PriorityOptions = [
    { label: 'All', value: 'All' },
    { label: 'Urgent', value: EPriorityCarInService.URGENT },
    { label: 'High', value: EPriorityCarInService.HIGH },
    { label: 'Medium', value: EPriorityCarInService.MEDIUM },
    { label: 'Low', value: EPriorityCarInService.LOW },
];

export const PriorityColorMapping = {
    [EPriorityCarInService.URGENT]: '#f50',
    [EPriorityCarInService.HIGH]: '#7357FF',
    [EPriorityCarInService.MEDIUM]: '#fa8c16',
    [EPriorityCarInService.LOW]: '#1890ff',
};

export const PriorityGradientColorMapping = {
    [EPriorityCarInService.URGENT]: 'linear-gradient(180deg, #ffbb96, #FFF8F4)',
    [EPriorityCarInService.HIGH]: 'linear-gradient(180deg, #b39eff, #EFEBFF)',
    [EPriorityCarInService.MEDIUM]: 'linear-gradient(180deg, #FFDFB4, #FFF8F0)',
    [EPriorityCarInService.LOW]: 'linear-gradient(180deg, #91d5ff, #F4FAFF)',
};

export const PriorityOptionsMapping = {
    [EPriorityCarInService.URGENT]: 'Urgent',
    [EPriorityCarInService.HIGH]: 'High',
    [EPriorityCarInService.MEDIUM]: 'Medium',
    [EPriorityCarInService.LOW]: 'Low',
};

export type TServiceDetail = {
    serviceDetailId?: number;
    serviceId?: number;
    service: TService | undefined;
    startTime?: string;
    stopTime?: string;
    serviceDetailStatus?: EStatusOfService;
    userId?: number;
    createdAt?: string;
    user?: TUserService | undefined;
    duration?: string;
    durationInMinutes?: number;
};

export type TCarInService = {
    carInServiceId?: number;
    completedBy?: string;
    carId?: string | number | undefined;
    priority?: EPriorityCarInService;
    status?: EStatusCarInService;
    createdAt?: string;
    car?: TCar | undefined;
    services: TServiceDetail[] | undefined;
    createdBy?: string | number | undefined;
};

// NotModify 0, Add 1, Update 2, Delete 3
export enum EUpdateType {
    NOT_MODIFY = 0,
    ADD = 1,
    UPDATE = 2,
    DELETE = 3,
}

export type TServiceRequest = {
    serviceId: number;
    serviceDetailId?: number;
    startTime?: string;
    stopTime?: string;
    serviceDetailStatus?: EStatusOfService;
    userId?: number;
    createdAt?: string;

    updateType?: EUpdateType;
};

export type TCarInServiceRequest = {
    carId: string | number | undefined;
    carInServiceId?: number;
    services: TServiceRequest[];
    priority?: EPriorityCarInService;
    status?: EStatusCarInService;
    createdAt?: string;
    completedBy?: string;
    createdBy?: string | number | undefined;
};
