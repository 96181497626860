import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ServicePageList } from './list';
import { TService } from '../start-work-order/type';
import { setLoading } from '../../redux/slices/appInfo';
import { getServicePaging } from '../../api/setting/services';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';
import { DEFAULT_PAGE_SIZE } from '../../utils/constants';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { removeUndefinedAttribute } from '../../utils';

const Wrapper = styled.div``;

type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
};
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
};
const SortDataContext = React.createContext<TSortDataContext>(
    {} as TSortDataContext
);
export const useSortData = () => React.useContext(SortDataContext);

export const ServicesPage: React.FC = () => {
    const dispatch = useDispatch();
    const [pagination, setPagination] = React.useState<IPagination | undefined>(
        undefined
    );
    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        pageSize: { type: 'number', default: DEFAULT_PAGE_SIZE },
        sortBy: { type: 'string', default: Direction.DESC },
        orderBy: { type: 'string', default: 'createdAt' },
    };
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const [sortData, setSortData] = React.useState<TSortData>({
        field: 'createdAt',
        order: 'desc',
    });
    const onChangeSort = React.useCallback(
        (data: TSortData) => {
            setSortData(data);
            const searchQueryParams: SearchQueryParams = {
                page: 1,
                pageSize: filterParams?.pageSize || DEFAULT_PAGE_SIZE,
                sortBy: data.order as Direction,
                orderBy: data.field,
            };
            onLoadData(searchQueryParams);
        },
        [filterParams]
    );
    const [serviceList, setServiceList] = React.useState<TService[]>([]);

    const onLoadData = React.useCallback(
        async (queryParams: SearchQueryParams | undefined) => {
            try {
                dispatch(setLoading(true));
                const newPayload = removeUndefinedAttribute(queryParams);
                setFilterParams(newPayload);
                const result = await getServicePaging(newPayload);
                if (result.status === 200) {
                    setServiceList(result.data?.data ?? []);
                    setPagination({
                        page: result.data?.currentPage ?? 1,
                        pageSize: result.data?.pageSize ?? DEFAULT_PAGE_SIZE,
                        totalCount: result.data?.totalRecords ?? 0,
                        totalPage: result.data?.totalPages ?? 0,
                        order: newPayload?.sortBy as Direction,
                        orderBy: newPayload?.orderBy,
                    });
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch]
    );

    const onChangePaging = React.useCallback(
        (values: any) => {
            const searchQueryParams: SearchQueryParams = {
                page: values.page,
                pageSize: pagination?.pageSize ?? 10,
                sortBy: pagination?.order as Direction,
                orderBy: pagination?.orderBy ?? 'createdAt',
            };
            onLoadData(searchQueryParams);
        },
        [pagination]
    );

    React.useEffect(() => {
        onLoadData({
            page: 1,
            pageSize: filterParams?.pageSize || DEFAULT_PAGE_SIZE,
            sortBy: Direction.DESC,
            orderBy: 'createdAt',
        });
    }, []);

    const onReload = React.useCallback(() => {
        onLoadData({
            page: 1,
            pageSize: filterParams?.pageSize || DEFAULT_PAGE_SIZE,
            sortBy: filterParams.order as Direction,
            orderBy: filterParams.orderBy,
        });
    }, [filterParams]);

    return (
        <Wrapper className="flex flex-col items-center justify-start h-full">
            <div className="flex flex-row items-start h-full w-full">
                <SortDataContext.Provider
                    value={{ sortData, setSortData: onChangeSort }}
                >
                    <ServicePageList
                        serviceList={serviceList}
                        setServiceList={setServiceList}
                        pagination={pagination}
                        onChangePaging={onChangePaging}
                        onReload={onReload}
                        filterParams={filterParams}
                        onFilterData={onLoadData}
                    />
                </SortDataContext.Provider>
            </div>
        </Wrapper>
    );
};
