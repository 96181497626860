import React from 'react';
import {
    EStatusOfService,
    StatusOfServiceColorMapping,
    StatusOfServiceMapping,
    StatusOfServiceStateMapping,
    TCarInService,
    TServiceDetail,
} from '../start-work-order/type';
import { ServicesActionsPage } from './services-actions';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useUpdateService } from '../../hooks/useUpdateService';

type ViewServicesProps = {
    task: TCarInService | undefined;
};

export const ViewServices: React.FC<ViewServicesProps> = ({ task }) => {
    const { serviceDetails, onUpdateStatus } = useUpdateService();
    const [taskSelected, setTaskSelected] = React.useState<
        TCarInService | undefined
    >(undefined);

    React.useEffect(() => {
        setTaskSelected(task);
    }, [task]);

    React.useEffect(() => {
        if (serviceDetails) {
            setTaskSelected(serviceDetails);
        }
    }, [serviceDetails]);

    const onChangeStatusService = React.useCallback(
        (status: EStatusOfService, service: TServiceDetail) => {
            const newServices: TServiceDetail[] = taskSelected?.services?.map(
                item => {
                    if (item.serviceDetailId === service.serviceDetailId) {
                        return {
                            ...item,
                            serviceDetailStatus: status,
                        };
                    }
                    return item;
                }
            ) as TServiceDetail[];
            const newServiceDetail = newServices.find(
                item => item.serviceDetailId === service.serviceDetailId
            ) as TServiceDetail;
            const newTaskSelected: TCarInService = {
                ...taskSelected,
                services: newServices,
            };
            setTaskSelected(newTaskSelected);
            onUpdateStatus(status, newServiceDetail, newTaskSelected);
        },
        [onUpdateStatus, taskSelected]
    );

    const columns: ColumnsType<TServiceDetail> = [
        {
            title: 'No',
            dataIndex: 'serviceId',
            key: 'serviceId',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Service Name',
            dataIndex: 'service',
            key: 'service',
            render: service => {
                return service?.name;
            },
        },
        {
            title: 'Status',
            dataIndex: 'serviceDetailStatus',
            key: 'serviceDetailStatus',
            render: status => {
                const statusValue = StatusOfServiceMapping[status];
                return statusValue ? (
                    <Tag color={StatusOfServiceColorMapping[status]}>
                        {StatusOfServiceStateMapping[status]}
                    </Tag>
                ) : (
                    ''
                );
            },
        },
        {
            title: 'Assignee',
            dataIndex: 'user',
            key: 'user',
            render: user => user?.username,
        },
        {
            title: 'Duration',
            dataIndex: 'durationInMinutes',
            key: 'durationInMinutes',
            width: 140,
            render: durationInMinutes => {
                const durationInMinutesValue =
                    durationInMinutes && durationInMinutes < 60
                        ? durationInMinutes
                        : durationInMinutes
                          ? (durationInMinutes / 60).toFixed(2)
                          : 0;
                const renderText = durationInMinutes < 60 ? 'minutes' : 'hours';
                return (
                    <div>
                        {durationInMinutesValue
                            ? `${durationInMinutesValue} ${renderText}`
                            : ''}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            align: 'end',
            key: 'actions',
            render: (text, record) => (
                <ServicesActionsPage
                    service={record}
                    size="small"
                    mobileMode={false}
                    oneService={false}
                    carInServiceSelected={task}
                    onUpdateStatus={onChangeStatusService}
                />
            ),
        },
    ];

    const renderFooter = React.useMemo(() => {
        const durationInMinutes = taskSelected?.services?.reduce(
            (acc, service) => acc + (service?.durationInMinutes || 0),
            0
        ) as number;
        const countDuration =
            durationInMinutes && durationInMinutes < 60
                ? durationInMinutes
                : durationInMinutes
                  ? (durationInMinutes / 60).toFixed(2)
                  : 0;
        const renderText = durationInMinutes < 60 ? 'minutes' : 'hours';

        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted">Total Duration:</span>
                    <span className="font-bold">
                        {countDuration} {renderText}
                    </span>
                </div>
            </div>
        );
    }, [taskSelected]);

    return (
        <div className="flex flex-col relative">
            <div className="relative flex overflow-auto max-h-full flex-col items-start gap-2 bg-white h-full w-full rounded-[8px] p-[10px]">
                <div className="flex flex-col w-full gap-2">
                    <Table
                        key="service-list"
                        className="white-header w-full"
                        columns={columns}
                        id="serviceDetailId"
                        dataSource={taskSelected?.services ?? []}
                        rowClassName="cursor-pointer data-row"
                        rowKey="serviceDetailId"
                        locale={{
                            emptyText: (
                                <div className="flex flex-col items-center justify-center w-full h-[200px]">
                                    No data
                                </div>
                            ),
                        }}
                        pagination={false}
                        footer={() => renderFooter}
                    />
                </div>
            </div>
        </div>
    );
};
