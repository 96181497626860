import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form, notification as Notification } from 'antd';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackForm } from '../../../components/form/form';
import {
    AutoTrackInput,
    AutoTrackInputPassword,
} from '../../../components/form/input';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ICCheckFill, ICClose, ICWarning } from '../../../icons';

import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { TUserService } from '../../start-work-order/type';
import { createUser } from '../../../api/setting/users';
import { auth } from '../../../firebase/firebaseConfig';
import { Role } from '../../../utils/constants';
import { validatePasswordStrength } from '../../../utils/passwordValidator';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Wrapper = styled.div`
    background-color: #7357ff;
    background-image: url('/images/bg-pattern.png');
    background-size: cover;
    background-repeat: no-repeat;
`;

type FieldType = {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
};

export const RegisterPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [registerForm] = useForm();
    const [createUserWithEmailAndPassword, registeredUser] =
        useCreateUserWithEmailAndPassword(auth);
    const [api, contextHolder] = Notification.useNotification();
    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => {
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const onFinished = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const emailVal = registerForm.getFieldValue('email') ?? '';
            const emailLower = emailVal.toLowerCase();
            await createUserWithEmailAndPassword(
                emailLower,
                registerForm.getFieldValue('password')
            );
        } catch (error) {
            dispatch(setLoading(false));
            openNotification(
                'error',
                'Register failed',
                'Please check your email and password'
            );
        }
    }, [dispatch, registeredUser, openNotification]);

    const doSaveUser = React.useCallback(
        async (payload: TUserService) => {
            try {
                dispatch(setLoading(true));
                const result = await createUser(payload);
                if (result.status === 201) {
                    openNotification(
                        'success',
                        'Save User success',
                        'User has been created'
                    );
                }
                setTimeout(() => {
                    navigate('/login');
                }, 500);
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Please check your input data';
                openNotification('error', 'Save User failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, navigate, openNotification]
    );

    React.useEffect(() => {
        if (registeredUser?.user) {
            const username =
                registerForm.getFieldValue('firstName') +
                ' ' +
                registerForm.getFieldValue('lastName');
            const payload: TUserService = {
                email: registerForm.getFieldValue('email'),
                username,
                passwordHash: registerForm.getFieldValue('password'),
                roles: [Role.TECHNICIAN],
                phoneNumber: registerForm.getFieldValue('phoneNumber'),
            };
            doSaveUser(payload);
        }
    }, [registeredUser]);

    return (
        <Wrapper className="flex w-screen h-screen items-center justify-center bg-surface-low">
            {contextHolder}
            <div className="shadow-e-03 max-md:max-w-[478px] max-md:w-auto w-[478px] p-[32px] flex flex-col gap-[24px] rounded-[12px] overflow-hidden box-border bg-white">
                <div className="flex justify-center">
                    <h1 className="text-heading-5 text-high-em font-semibold">
                        Register Auto Track
                    </h1>
                </div>
                <AutoTrackForm
                    form={registerForm}
                    name="registerForm"
                    layout="vertical"
                    labelCol={{ span: 8 }}
                    onFinish={onFinished}
                    childNode={
                        <>
                            <div className="form flex flex-col w-full gap-[16px]">
                                <div>
                                    <Form.Item<FieldType>
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message:
                                                    'Please input your first name!',
                                            },
                                        ]}
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            maxLength={50}
                                            placeholder="First Name"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message:
                                                    'Please input your last name!',
                                            },
                                        ]}
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            maxLength={50}
                                            placeholder="Last Name"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message:
                                                    'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            maxLength={50}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your password!',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    const { isValid } =
                                                        validatePasswordStrength(
                                                            value
                                                        );
                                                    if (isValid) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not strong enough'
                                                        )
                                                    );
                                                },
                                            },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Phone Number"
                                        name="phoneNumber"
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            placeholder="Phone Number"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-[24px] pt-[16px]">
                                <div>
                                    <AutoTrackButton htmlType="submit">
                                        Register
                                    </AutoTrackButton>
                                </div>
                                <div className="flex gap-[16px] items-center justify-center text-center text-high-em text-body">
                                    <Link
                                        to="/login"
                                        className="underline text-info-500 font-medium"
                                    >
                                        Back to login
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        </Wrapper>
    );
};
